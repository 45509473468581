/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { GizmoManager } from "@babylonjs/core/Gizmos/gizmoManager";
import { Quaternion } from "@babylonjs/core/Maths/math.vector";

import type { Vector3, Quaternion  as NetworkQuaternion } from "@src/util/NetworkDataUtils";

export class ObjectManager {
    gizmoManager: GizmoManager;
    private modelRootMesh: any;

    constructor(scene: any) {
        this.modelRootMesh = scene.getMeshByName("modelRootMesh");
        this.gizmoManager = new GizmoManager(scene);
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;
    }


    enableGizmos() {
        this.gizmoManager.attachToMesh(this.modelRootMesh);
    }

    disableGizmos() {
        this.gizmoManager.attachToMesh(null);
    }

    getPosition() {
        return this.modelRootMesh.position.asArray() as Vector3;
    }

    getRotation() {
        const quaternion = Quaternion.FromEulerVector(this.modelRootMesh.rotation);
        return quaternion.asArray() as NetworkQuaternion;
    }

    getScale() { /* TODO */ }

    updatePosition(position: Vector3) {
        this.modelRootMesh.position.x = position[0];
        this.modelRootMesh.position.y = position[1];
        this.modelRootMesh.position.z = position[2];
    }

    updateRotation(rotation: NetworkQuaternion) {
        const euler = Quaternion.FromArray(rotation).toEulerAngles();
        this.modelRootMesh.rotation = euler;
    }

    updateScale() { /* TODO */ }

    getRootMesh() {
        return this.modelRootMesh;
     }
}
