/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, Heading, Image, View } from "@adobe/react-spectrum";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
    AppSettingsTabsView,
    AppSettingsTabType,
} from "../../components/AppSettingsTabsView";
import { useAssetContext } from "../../contexts/AssetContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import appIcon from "../../images/rw_appicon_noshadow_128.png";
import { getCurrentTime, getTrimmedString } from "../../util/StringUtils";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

import type { Key } from "@react-types/shared";

interface Users {
    id: string;
    name: string;
    aviUrl: string;
    isMuted: boolean;
};

export function AppSettingsHeader() {
    const { assetMetadata } = useAssetContext();
    const { locale } = useLocaleContext();
    const { t } = useTranslation("common");

    return (
        <Flex direction="row" alignItems="center" gap="40px">
            <Image
                width="30px"
                height="30px"
                minWidth="100px"
                minHeight="100px"
                alt="highfive app icon"
                src={appIcon}
                UNSAFE_style={{
                    borderRadius: "8px",
                }}
            />
            <Flex direction="column" alignItems="start">
                <Text
                    UNSAFE_style={{
                        fontSize: "1.95em",
                    }}>
                    {getCurrentTime(locale)}
                </Text>
                <Heading level={1}>
                    {assetMetadata && assetMetadata.displayName
                        ? assetMetadata.displayName
                        : t("account.home")}
                </Heading>
            </Flex>
        </Flex>
    );
}

export function AppSettingsDialog() {
    const [selectedSettingsTab, setSelectedSettingsTab] = useState<Key>(
        AppSettingsTabType.preferences,
    );
    const { accessToken } = useHi5UserContext();

    const { assetMetadata, setAssetUrn, getMetadata } =
        useAssetContext();
    const { locale } = useLocaleContext();
    const { postMessageBusApi } = usePostMessageContext();

    const [searchParams] = useSearchParams();
    const urn = searchParams.get("urn") || "";
    const { t } = useTranslation("common");

    useEffect(() => {
        if (!(accessToken && urn)) return;
        setAssetUrn(urn);
        getMetadata(urn, true);
    }, [accessToken, urn]);

    const [lang, setLang] = useState<string>("");
    const [uiSize, setUiSize] = useState<string>("");
    const [controller, setController] = useState<string>("");
    const [haptics, setHaptics] = useState<boolean>(false);
    const [preset, setPreset] = useState<string>("");
    const [resolution, setResolution] = useState<boolean>(false);
    const [spaceWarp, setSpaceWarp] = useState<boolean>(false);
    const [customResolution, setCustomResolution] = useState<string>("");

    const [userList, setUserList] = useState<Users[]>([]);

    function AddUserToList(data: any) {
        setUserList(data.detail);
    }

    function RemoveUserFromList(data: any) {
        console.log("userLeft", JSON.stringify(data));
        const updatedUserList = userList.filter(
            (user) => user.id !== data.detail.id,
        );
        setUserList(updatedUserList);
    }

    if (postMessageBusApi) {
        postMessageBusApi.addEventListener(
            "init-app-settings",
            (data: any) => {
                if (data.detail.lang.key) {
                    console.log(
                        "Got language",
                        JSON.stringify(data.detail.lang.key),
                    );
                }

                console.log("initAppSettings", JSON.stringify(data.detail));
                setLang(data.detail.lang.key);
                setUiSize(data.detail.uiSize.key);
                setController(data.detail.controller.key);

                if (data.detail.haptics.val == "On") setHaptics(true);
                else setHaptics(false);

                setPreset(data.detail.preset.key);

                if (data.detail.resolution.val == "On") setResolution(true);
                else setResolution(false);

                if (data.detail.spaceWarp.val == "On") setSpaceWarp(true);
                else setSpaceWarp(false);

                setCustomResolution(data.detail.customResolution.key);
            },
            false,
        );

        postMessageBusApi.addEventListener(
            "user-joined",
            (data: any) => {
                console.log("userJoined", JSON.stringify(data.detail));
                AddUserToList(data);
            },
            false,
        );

        postMessageBusApi.addEventListener(
            "user-left",
            (data: any) => {
                console.log("userLeft", JSON.stringify(data.detail));
                RemoveUserFromList(data);
            },
            false,
        );

        postMessageBusApi.addEventListener(
            "user-joined",
            (data: any) => {
                console.log("userJoined", JSON.stringify(data.detail));
                AddUserToList(data);
            },
            false,
        );

        postMessageBusApi.addEventListener(
            "user-left",
            (data: any) => {
                console.log("userLeft", JSON.stringify(data.detail));
                RemoveUserFromList(data);
            },
            false,
        );
    }

    const charLimit = 23;

    return (
        <View
            height="100%"
            UNSAFE_style={{
                overflow: "hidden",
            }}>
            <View
                height="50px"
                width="100%"
                paddingBottom="35px"
                paddingTop="30px"
                paddingX="30px">
                <Flex direction="row" alignItems="center" gap="20px">
                    <Image
                        width="30px"
                        height="30px"
                        minWidth="64px"
                        minHeight="64px"
                        alt="highfive app icon"
                        marginStart="20px"
                        marginBottom="45px"
                        marginTop="12px"
                        src={appIcon}
                        UNSAFE_style={{
                            borderRadius: "3px",
                            overflow: "hidden",
                        }}
                    />
                    <Flex direction="column" alignItems="start">
                        <Text
                            UNSAFE_style={{
                                fontSize: "1.75em",
                            }}>
                            {getCurrentTime(locale)}
                        </Text>
                        <Text
                            marginBottom="30px"
                            UNSAFE_style={{
                                fontSize: "1.8em",
                                fontWeight: "bold",
                            }}>
                            {assetMetadata && assetMetadata.displayName
                                ? getTrimmedString(
                                      assetMetadata.displayName,
                                      charLimit,
                                  )
                                : t("account.home")}
                        </Text>
                    </Flex>
                </Flex>
            </View>
            <View
                height="95%"
                width="100%"
                backgroundColor="gray-100"
                paddingY="10px"
                paddingX="30px">
                {AppSettingsTabsView(
                    selectedSettingsTab,
                    setSelectedSettingsTab,
                    lang,
                    uiSize,
                    controller,
                    haptics,
                    preset,
                    resolution,
                    spaceWarp,
                    customResolution,
                    userList,
                )}
            </View>
        </View>
    );
}
