/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Flex,
    Heading,
    View,
    TextField,
} from "@adobe/react-spectrum";
import { StudioTool } from "@components/studio";
import { ToastQueue } from "@react-spectrum/toast";
import { useMessagingContext } from "@shared/client";
import Delete from "@spectrum-icons/workflow/Delete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProgressCircleView } from "./ProgressCircleView";
import { ShareSheetView } from "./ShareSheet";
import { StudioView } from "./StudioView";
import { useEnvironmentBuilder } from "@src/hooks/useEnvironmentBuilder";
import { useRedirects } from "@src/hooks/useRedirects";
import { useSceneEnvironment } from "@src/hooks/useSceneEnvironment";

import type { AssetContextValue } from "../contexts/AssetContext";
import type { SceneManager } from "@components/studio/src/scene/SceneManager";

interface Props {
    assetId: string;
    assetContext: AssetContextValue;
    assetName: string;
    setAssetName: (assetName: string) => void;
    confirmHandler: () => void;
}

export function ShareDialog({
    assetId,
    assetContext,
    assetName,
    setAssetName,
    confirmHandler,
}: Props) {
    const { t } = useTranslation(["web", "common"]);
    const { homeRedirect, homeModalRedirect } = useRedirects();
    const { success, error } = useMessagingContext();

    const [modelUrl, setModelUrl] = useState("");

    const [sceneManager, setSceneManager] = useState<SceneManager>();
    const envState = useSceneEnvironment(assetContext, assetId);
    useEnvironmentBuilder(sceneManager, envState);

    const { online, getDownloadUrl, deleteAsset } = assetContext;

    function cancel() {
        homeRedirect(true);
    }

    useEffect(() => {
        if (online) {
            (async () => {
                const downloadUrl = await getDownloadUrl(assetId);
                if (downloadUrl) {
                    setModelUrl(downloadUrl);
                }
            })();
        }
    }, [assetId, online]);

    return (
        <View width="80vw" height="80vh" backgroundColor="gray-100">
            {modelUrl ? (
                <Flex direction="row" width="100%" height="100%">
                    <View width="65%">
                        <StudioView
                            modelUrl={modelUrl}
                            editorTools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            setSceneManager={setSceneManager}
                        />
                    </View>
                    <Flex
                        width="35%"
                        direction="column"
                        UNSAFE_style={{
                            padding:
                                "var(--spectrum-global-dimension-size-250)",
                            overflow: "auto",
                        }}>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            height="100%">
                            <View flexGrow={1} overflow="auto">
                                <Heading level={1} margin={0}>
                                    <TextField
                                        value={assetName}
                                        onChange={setAssetName}
                                    />
                                </Heading>
                                <Heading level={3}>
                                    {t("web:sharePanel.title")}
                                </Heading>
                                <Flex
                                    direction="column"
                                    marginTop="size-300"
                                    gap="size-150">
                                    {assetId && assetName && (
                                        <ShareSheetView
                                            assetUrn={assetId}
                                            assetName={assetName}
                                            hideCloseButton
                                        />
                                    )}
                                </Flex>
                                <View padding="size-250">
                                    <Button
                                        width="100%"
                                        variant="secondary"
                                        onPress={() => {
                                            deleteAsset(assetId, true)
                                                .then(() => {
                                                    success(
                                                        t(
                                                            "common:toast.delete.successful",
                                                        ),
                                                    );
                                                })
                                                .catch(() => {
                                                    error(
                                                        t(
                                                            "common:toast.delete.fail",
                                                        ),
                                                    );
                                                });
                                            cancel();
                                        }}>
                                        <Delete
                                            UNSAFE_style={{
                                                marginInlineEnd: "6px",
                                            }}
                                        />
                                        {t("web:actions.delete")}
                                    </Button>
                                </View>
                            </View>
                            <Flex
                                direction="column"
                                justifyContent="end"
                                alignItems="end">
                                <ButtonGroup marginBottom="size-200">
                                    <Button
                                        variant="secondary"
                                        onPress={cancel}>
                                        {t("common:actions.cancel")}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onPress={() =>
                                            homeModalRedirect(
                                                "environment",
                                                assetId,
                                            )
                                        }>
                                        {t("common:actions.back")}
                                    </Button>
                                    <Button
                                        variant="accent"
                                        onPress={() => {
                                            ToastQueue.info(
                                                t("toast.review.createSuccess"),
                                                { timeout: 5000 },
                                            );
                                            confirmHandler();
                                        }}
                                        autoFocus>
                                        {t("web:actions.done")}
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <ProgressCircleView />
            )}
        </View>
    );
}
