/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    View,
    Button,
    Text,
    Header,
    Image,
    Badge,
} from "@adobe/react-spectrum";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { UserProfileView } from "../../components/UserProfileView";
import { useAssetContext } from "../../contexts/AssetContext";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import noThumbnail from "../../images/no-thumbnail.svg";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function EnterReviewDialog() {
    const { t } = useTranslation(["common"]);

    const { accessToken, userProfile, avatarUrl } = useHi5UserContext();
    const {
        setAssetUrn,
        getMetadata,
        getThumbnail,
        getDownloadUrl,
        assetUrn,
        assetMetadata,
        assetDownloadUrl,
        getEnvMetadata,
        service,
    } = useAssetContext();
    const { postMessageBusApi } = usePostMessageContext();
    const [searchParams] = useSearchParams();
    const urn = searchParams.get("urn") || "";

    useEffect(() => {
        if (!(accessToken && urn)) return;
        setAssetUrn(urn);
        getMetadata(urn, true);
    }, [accessToken, urn]);

    useEffect(() => {
        const isGetDownloadUrlReady =
            service && urn && assetUrn && urn === assetUrn;
        if (!isGetDownloadUrlReady) return;
        getDownloadUrl(assetUrn);
    }, [service, assetUrn]);

    const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>("");
    const fetchThumbnail = useCallback(async () => {
        if (!(service && assetUrn)) return;
        const thumbnail = await getThumbnail(assetUrn, false);
        setThumbnailUrl(thumbnail);
    }, [service, assetUrn]);

    useEffect(() => {
        fetchThumbnail().catch(console.error);
    }, [fetchThumbnail]);

    const [users, setUsers] = useState<number>(0);

    if (postMessageBusApi) {
        postMessageBusApi.addEventListener(
            "users-in-review",
            (data: any) => {
                setUsers(data.detail.users);
            },
            false,
        );
    }

    function FormatReviewString() {
        if (users > 1) {
            return (
                t("enterReview.playerCount.plural.1") +
                users +
                t("enterReview.playerCount.plural.2")
            );
        } else {
            return (
                t("enterReview.playerCount.singular.1") +
                users +
                t("enterReview.playerCount.singular.2")
            );
        }
    }

    function thumbnail() {
        if (thumbnailUrl) {
            return (
                <Flex direction="column">
                    {users > 0 ? (
                        <Badge
                            variant="neutral"
                            position="absolute"
                            top="430px"
                            left="60px"
                            width="85%">
                            <Text
                                UNSAFE_style={{
                                    fontSize: "3em",
                                }}>
                                {FormatReviewString()}
                            </Text>
                        </Badge>
                    ) : (
                        <></>
                    )}
                    <Image
                        width="400px"
                        alt={t("accessibility.asset.thumbnail")}
                        src={thumbnailUrl}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                </Flex>
            );
        } else {
            return (
                <Image
                    width={"size-2000"}
                    height={"size-2000"}
                    alt={t("accessibility.asset.thumbnail")}
                    src={noThumbnail}
                />
            );
        }
    }

    function goHome() {
        if (postMessageBusApi) postMessageBusApi.showDialog("log-in-check");
    }

    return (
        <View
            paddingTop="size-500"
            paddingX="size-700"
            height="100%"
            UNSAFE_style={{
                boxSizing: "border-box",
            }}>
            <Flex
                direction="column"
                gap="size-400"
                justifyContent="space-between">
                <View height="size-350">
                    <Header
                        UNSAFE_style={{
                            fontSize: "2.2em",
                        }}>
                        {assetMetadata && assetMetadata.displayName}
                    </Header>
                </View>
                <View
                    backgroundColor="gray-200"
                    height="400px"
                    UNSAFE_style={{
                        borderRadius: "8px",
                        paddingTop: "8px",
                    }}>
                    <Flex
                        direction="row"
                        width="100%"
                        height="400px"
                        alignItems="center"
                        justifyContent="center">
                        {thumbnail()}
                    </Flex>
                </View>
                <View>
                    <UserProfileView
                        userProfile={userProfile}
                        avatarUrl={avatarUrl}
                        isForVr={true}
                    />
                </View>
                <View>
                    <Flex
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        gap="2%">
                        <Button
                            onPress={goHome}
                            width="100%"
                            variant="secondary"
                            style="fill"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("navigation.goHome")}
                            </Text>
                        </Button>
                        <Button
                            isDisabled={assetDownloadUrl == "" || users >= 10}
                            onPress={() => {
                                getEnvMetadata(assetUrn, true).then((envMeta) => {
                                    postMessageBusApi &&
                                        postMessageBusApi.enterReview(
                                            assetUrn,
                                            assetDownloadUrl,
                                            envMeta
                                        );
                                })
                            }}
                            width="100%"
                            variant="cta"
                            UNSAFE_style={{
                                borderRadius: "100px",
                            }}>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.5em",
                                }}>
                                {t("navigation.enterReview")}
                            </Text>
                        </Button>
                    </Flex>
                    <Flex direction="row" marginTop="10px">
                        {users >= 10 ? (
                            <Text
                                UNSAFE_style={{
                                    fontSize: "2.3em",
                                    color: "red",
                                }}>
                                {t("enterReview.playerCount.max")}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
