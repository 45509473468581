/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export interface AudioPreferences {
    muted: boolean;
    currentAudioDeviceId?: string;
}

const storageKey = "reviewer-audio-preferences";
const defaultPreferences: AudioPreferences = {
    muted: false,
};

function writeAudioPreferences(preferences: AudioPreferences) {
    window.localStorage.setItem(storageKey, JSON.stringify(preferences));
}

function readAudioPreferences() {
    try {
        const savedValue = window.localStorage.getItem(storageKey);
        if (savedValue) {
            return JSON.parse(savedValue) as AudioPreferences;
        }
    } catch (e) {
        console.error("Error getting audio preferences", e);
    }
    return defaultPreferences;
}

export function useAudioControls(
    mutedOverride?: boolean,
    currentDeviceIdOverride?: string,
) {
    const savedPreferences = readAudioPreferences();
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [currentAudioDeviceId, setCurrentAudioDeviceId] = useState(
        currentDeviceIdOverride || savedPreferences.currentAudioDeviceId || "",
    );
    const [muted, setMuted] = useState(mutedOverride || savedPreferences.muted);

    useEffect(() => {
        writeAudioPreferences({
            currentAudioDeviceId,
            muted,
        });
    }, [currentAudioDeviceId, muted]);

    useEffect(() => {
        (async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });

                const devices = await navigator.mediaDevices.enumerateDevices();
                //get only input devices
                const inputDevices = devices.filter(
                    (device) => device.kind === "audioinput",
                );
                if (inputDevices.length > 0) {
                    setAudioDevices(inputDevices);
                    setCurrentAudioDeviceId((currentId) => {
                        if (!currentId) {
                            return inputDevices[0].deviceId;
                        }
                        return currentId;
                    });
                }
            } catch (error) {
                console.error("Error getting devices", error);
            }
        })();
    }, []);

    return {
        audioDevices,
        currentAudioDeviceId,
        setCurrentAudioDeviceId,
        muted,
        setMuted,
    };
}
