/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function LogInCheck() {
    const [searchParams] = useSearchParams();
    const maybeError = searchParams.get('error');
    if (maybeError && maybeError === 'security-jump-confirmation') {
        const maybeRedirect = searchParams.get('error_redirect_uri');
        if (maybeRedirect) {
            try {
                const url = new URL(maybeRedirect);
                console.log('INFO Redirecting to ', url);
                // if we get here, url seems valid.
                window.location.replace(url);
            } catch (err) {
                console.warn('Invalid redirect URL', err);
            }
        }
    }
    const { postMessageBusApi } = usePostMessageContext();
    const { accessToken, imsReady, userProfile } = useHi5UserContext();

    const [isVRReady, setIsVRReady] = useState(false);

    const useSUSIKey = "useSUSI";

    useEffect(() => {
        if (!postMessageBusApi) return;
        postMessageBusApi.addEventListener("set-vr-data", (data: any) => {
            localStorage.setItem(useSUSIKey, data.detail.useSUSI);
            setIsVRReady(true);
        });
    }, [postMessageBusApi]);

    async function checkLoggedIn() {
        if (!postMessageBusApi) return;
        if (accessToken === "") return; // default value - access token hasn't yet been checked
        if (!imsReady) return;

        const useSUSIData = localStorage.getItem(useSUSIKey);

        if (userProfile) {
            postMessageBusApi.showDialog("start-screen");
        } else {
            if (!useSUSIData) {
                return;
            };
            postMessageBusApi.showDialog("signed-out", `use-susi=${useSUSIData}`);
        }
    }

    useEffect(() => {
        checkLoggedIn();
    }, [postMessageBusApi, imsReady, accessToken, userProfile, isVRReady]);

    return <div>Checking if logged in...</div>;
}
