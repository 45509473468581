/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Flex,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { StudioTool } from "@components/studio";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetTechInfoView } from "./AssetTechInfoView";
import { ProgressCircleView } from "./ProgressCircleView";
import { StudioView } from "./StudioView";
import {
    getAssetTechInfo,
    updateTechInfoFromViewer,
} from "../util/HealthCheckUtils";
import { useRedirects } from "@src/hooks/useRedirects";

import type {
    AssetFileData,
    AssetContextValue,
} from "../contexts/AssetContext";
import type { AdobeViewer } from "@3di/adobe-3d-viewer";

interface UploadPreviewDialogProps {
    assetId: string;
    assetContext: AssetContextValue;
    assetName: string;
    setAssetName: (assetName: string) => void;
    confirmHandler: () => void;
}

async function getFileData(file: File, originalFormat?: string) {
    const techInfo = await getAssetTechInfo(file, originalFormat);
    const data: AssetFileData = {
        name: file.name,
        url: URL.createObjectURL(file),
        file,
        techInfo,
    };
    return data;
}

export function PreviewDialog({
    assetId,
    assetContext,
    assetName,
    setAssetName,
    confirmHandler,
}: UploadPreviewDialogProps) {
    const { t } = useTranslation(["common", "web"]);
    const { homeRedirect } = useRedirects();

    const [previewFileData, setPreviewFileData] = useState<AssetFileData>();

    const {
        online,
        getDownloadUrl,
        getAssetAsBlob,
        getComponentData,
        getMetadata,
        setEnvMetadata,
    } = assetContext;

    function goBack() {
        setEnvMetadata(
            {
                physicalSize: undefined,
                environment: undefined,
                pedestal: undefined,
            },
            assetId,
        );
        homeRedirect(true);
    }

    function confirm() {
        confirmHandler();
    }

    useEffect(() => {
        if (online) {
            (async () => {
                const downloadUrl = await getDownloadUrl(assetId);
                if (!downloadUrl) return;
                const fileBlob = await getAssetAsBlob(assetId, downloadUrl);
                if (!fileBlob) return;
                const [meta, componentData] = await Promise.all([
                    getMetadata(assetId),
                    getComponentData(assetId),
                ]);
                const file = new File([fileBlob], meta?.displayName || "");
                const fileData = await getFileData(
                    file,
                    componentData?.originalFormat,
                );
                setPreviewFileData(fileData);
            })();
        }
    }, [assetId, online]);

    const [viewerInstance, setViewerInstance] = useState<
        AdobeViewer | undefined
    >();
    const [sceneInstance, setSceneInstance] = useState<any>();

    useEffect(() => {
        if (
            !(
                viewerInstance &&
                sceneInstance &&
                previewFileData &&
                online &&
                assetId
            )
        )
            return;
        if (!previewFileData.techInfo) return;
        const updatedTechInfo = updateTechInfoFromViewer(
            viewerInstance,
            sceneInstance,
            previewFileData.techInfo,
        );

        if (updatedTechInfo?.physicalSize.value) {
            setEnvMetadata(
                {
                    physicalSize: JSON.stringify(
                        updatedTechInfo?.physicalSize.value,
                    ),
                },
                assetId,
            );
        }
        setPreviewFileData({
            ...previewFileData,
            techInfo: updatedTechInfo,
        });
    }, [viewerInstance, sceneInstance, assetId, online]);

    return (
        <View width="80vw" height="80vh" backgroundColor="gray-100">
            {previewFileData ? (
                <Flex direction="row" width="100%" height="100%">
                    <View width="65%">
                        <StudioView
                            modelUrl={previewFileData.url}
                            editorTools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            setSceneInstance={setSceneInstance}
                            setViewerInstance={setViewerInstance}
                        />
                    </View>
                    <Flex
                        width="35%"
                        direction="column"
                        UNSAFE_style={{
                            padding:
                                "var(--spectrum-global-dimension-size-250)",
                            overflow: "auto",
                        }}>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            height="100%">
                            <View>
                                <TextField
                                    value={assetName}
                                    onChange={setAssetName}
                                />
                                <AssetTechInfoView
                                    techInfo={previewFileData.techInfo}
                                />
                            </View>
                            <Flex
                                direction="column"
                                justifyContent="end"
                                alignItems="end">
                                <ButtonGroup marginBottom="size-200">
                                    <Button
                                        variant="secondary"
                                        onPress={goBack}>
                                        {t("common:actions.cancel")}
                                    </Button>
                                    <Button
                                        variant="accent"
                                        onPress={confirm}
                                        autoFocus>
                                        {t("web:actions.next")}
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <ProgressCircleView />
            )}
        </View>
    );
}
