/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    newDCXComposite,
    createComposite,
    uploadNewComponent,
    pushComposite,
} from "@dcx/dcx-js";
import { generateUuid } from "@dcx/util";
import { ModelMediaExtensionsUsd } from "@shared/types";
import { mediaTypeModelUsd } from "@shared/types/src/extension-sets";

import { fileNameToExtension } from "./StringUtils";
import { HI5_API_KEY } from "../config";

import type { AssetMetadata } from "../contexts/AssetContext";
import type { AdobeHTTPService, AdobeRepoAPISession } from "@dcx/dcx-js";


export interface CreateCompositeResult {
    assetId: string
    componentId: string
    componentVersion: string
    originalExtension: string
}

export async function createAndPushComposite(
    service: AdobeHTTPService | undefined,
    session: AdobeRepoAPISession | undefined,
    file: File,
    parentAssetId: string,
    parentRepositoryId: string,
    uploadProgressCallback: (startBuf: number, endBuf: number) => void,
    allAssets: AssetMetadata[] | undefined,
    handleDuplicateNames: (fileName: string) => string,
) {
    if (!(service && session)) {
        console.error("error starting session");
        return undefined;
    }

    const ext = fileNameToExtension(file.name.toLocaleLowerCase()) as ModelMediaExtensionsUsd;

    if (!ext || !mediaTypeModelUsd.includes(ext)) {
        throw new Error(`Unrecognized file extension ${ext}`)
    }

    const compositeName = file.name.replace(/[^.]+$/, "usdcx");
    const unboundComposite = newDCXComposite(
        undefined,
        undefined,
        compositeName,
        generateUuid(),
        "application/vnd.adobe.usdcx+dcx",
    );

    const parentDirectory = {
        assetId: parentAssetId,
        repositoryId: parentRepositoryId,
    };

    const displayName = compositeName.replace(".usdcx", "");
    const compositeNameUnique = compositeName.replace(
        displayName,
        handleDuplicateNames(displayName),
    );

    let composite;
    try {
        composite = await createComposite(
            session,
            unboundComposite,
            parentDirectory,
            compositeNameUnique,
        );
    } catch (e: any) {
        console.error("createComposite failed", e);
        return undefined;
    }

    if (!composite) return undefined;
    const current = composite.current;

    const arrBuffer = await file.arrayBuffer();

    const onSliceBuffer = (
        startBuf: number,
        endBuf: number,
    ): Promise<ArrayBuffer> => {
        const slice = arrBuffer.slice(startBuf, endBuf);
        return new Promise((resolve) => {
            uploadProgressCallback(startBuf, endBuf);
            resolve(slice);
        });
    };

    const upload = uploadNewComponent(
        session,
        composite,
        onSliceBuffer,
        'application/octet-stream',
        generateUuid(),
        file.size,
    );

    const results = upload.then(async (uploadResults) => {
        current.rootNode.setValue("usdcx#version", "0");
        current.rootNode.setValue("usdcx#product-id", HI5_API_KEY);
        current.rootNode.setValue("usdcx#source-document", file.name);

        composite.current.addComponentWithUploadResults(
            displayName,
            "original",
            file.name,
            current.rootNode,
            uploadResults,
        );

        await pushComposite(session, composite, false);

        if (uploadResults) {
            const key = Object.keys(uploadResults.records)[0];
            return {
                assetId: uploadResults["compositeAssetId"],
                componentId: uploadResults.records[key].id,
                componentVersion: uploadResults.records[key].version,
                originalExtension: ext,
            };
        } else {
            return undefined;
        }

    }).catch((e) =>{
        console.error("error uploading composite", e);
        return undefined;
    })

    return {
        compositeId: composite.id,
        results,
        cancel: () => upload.abort()
    }
}
