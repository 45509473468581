/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Checkbox,
    Flex,
    Heading,
    Item,
    LabeledValue,
    NumberField,
    Picker,
    Text,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { StudioTool } from "@components/studio";
import Alert from "@spectrum-icons/workflow/Alert";
import Chain from "@spectrum-icons/workflow/Link";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProgressCircleView } from "./ProgressCircleView";
import { StudioView } from "./StudioView";
import { useEnvironmentBuilder } from "../hooks/useEnvironmentBuilder";
import { useRedirects } from "@src/hooks/useRedirects";
import type {
    Centering,
    Environment,
    EnvironmentState,
    Grounding,
    Pedestal,
    UpAxis,
} from "@src/scene/EnvironmentBuilder";
import { Units, getFormattedPhysicalSize } from "@src/util/StringUtils";

import type { AssetContextValue } from "../contexts/AssetContext";
import type { SceneManager } from "@components/studio/src/scene/SceneManager";

interface Props {
    assetId: string;
    assetContext: AssetContextValue;
    assetName: string;
    setAssetName: (assetName: string) => void;
    confirmHandler: () => void;
}

const unitMultiplierMap: Record<Units, number> = {
    cm: 100,
    m: 1,
};

function getScaleLinkedEnvValues(
    environment: EnvironmentState,
): Pick<EnvironmentState, "pedestal" | "environmentModel"> {
    const largest =
        Math.max(
            environment.size[0],
            environment.size[1],
            environment.size[2],
        ) * environment.scaling;
    if (largest <= 0.3) {
        return {
            environmentModel: "studio",
            pedestal: "small",
        };
    }
    if (largest > 0.3 && largest <= 0.9) {
        return {
            environmentModel: "garage",
            pedestal: "medium",
        };
    }
    return {
        environmentModel: "courtyard",
        pedestal: "large",
    };
}

function isPedDisabled(largest: number) {
    return !largest;
    // return largest > 0.9;
}

export function EnvironmentDialog({
    assetId,
    assetContext,
    assetName,
    setAssetName,
    confirmHandler,
}: Props) {
    const { t } = useTranslation("web");
    const sizeStrings = ["W", "H", "D"];
    const { homeRedirect, homeModalRedirect } = useRedirects();

    const [modelUrl, setModelUrl] = useState("");
    const [sizeUnits, setSizeUnits] = useState<Units>();

    const [environmentState, setEnvironmentState] =
        useState<EnvironmentState>();
    const [sceneManager, setSceneManager] = useState<SceneManager>();

    const oversize = useMemo(() => {
        if (environmentState) {
            const { size, scaling } = environmentState;
            return (
                size[0] * scaling > 10 ||
                size[1] * scaling > 4 ||
                size[2] * scaling > 12
            );
        }
        return false;
    }, [environmentState]);
    const largest = useMemo(
        () =>
            !environmentState
                ? 0
                : Math.max(
                      environmentState.size[0],
                      environmentState.size[1],
                      environmentState.size[2],
                  ) * environmentState.scaling,
        [environmentState],
    );

    useEnvironmentBuilder(sceneManager, environmentState);

    const { online, getDownloadUrl, getEnvMetadata, setEnvMetadata } =
        assetContext;

    function cancel() {
        setEnvMetadata(
            {
                environment: undefined,
                pedestal: undefined,
            },
            assetId,
        );
        homeRedirect(true);
    }

    useEffect(() => {
        if (online) {
            Promise.all([
                (async () => {
                    const downloadUrl = await getDownloadUrl(assetId);
                    if (downloadUrl) {
                        setModelUrl(downloadUrl);
                    }
                })(),
                (async () => {
                    const envMeta = await getEnvMetadata(assetId);
                    const initialEnvState: EnvironmentState = {
                        scaling: 1,
                        environmentModel: "default",
                        pedestal: "none",
                        groundModel: "ungrounded",
                        centerModel: "uncentered",
                        size: [0, 0, 0],
                        upAxis: "y+",
                    };

                    if (envMeta?.scaling) {
                        const val = parseFloat(envMeta.scaling);
                        initialEnvState.scaling = isNaN(val) ? 1 : val;
                    }

                    if (envMeta?.physicalSize) {
                        const size: [number, number, number] = JSON.parse(
                            envMeta.physicalSize,
                        );

                        const { physicalSizeUnit } =
                            getFormattedPhysicalSize(size);
                        setSizeUnits(physicalSizeUnit);
                        initialEnvState.size = size;
                        if (!envMeta.environment && !envMeta.pedestal) {
                            const defaultFields =
                                getScaleLinkedEnvValues(initialEnvState);
                            initialEnvState.environmentModel =
                                defaultFields.environmentModel;
                            initialEnvState.pedestal =
                                initialEnvState.environmentModel !== "courtyard"
                                    ? defaultFields.pedestal
                                    : "none";
                        }
                    }

                    if (envMeta?.environment) {
                        const environment = envMeta.environment as Environment;
                        initialEnvState.environmentModel = environment;
                    }

                    if (envMeta?.pedestal) {
                        const ped = envMeta.pedestal as Pedestal;
                        initialEnvState.pedestal = ped;
                    }

                    if ((envMeta?.centering as Centering) === "centered") {
                        initialEnvState.centerModel = "centered";
                    }

                    if ((envMeta?.grounding as Grounding) === "grounded") {
                        initialEnvState.groundModel = "grounded";
                    }

                    if (envMeta?.upAxis) {
                        initialEnvState.upAxis = envMeta?.upAxis as UpAxis;
                    }

                    setEnvironmentState(initialEnvState);
                })(),
            ]);
        }
    }, [assetId, online]);

    function updatePedestal(envState: EnvironmentState) {
        if (isPedDisabled(largest)) {
            envState.pedestal = "none";
        }
    }

    const unitsMultiplier = sizeUnits ? unitMultiplierMap[sizeUnits] : 1;
    function setScaling(newSize: number, oldSize: number) {
        setEnvironmentState((env) => {
            if (!env) return;

            const newEnv = {
                ...env,
                scaling: newSize / unitsMultiplier / oldSize,
            };

            const deps = getScaleLinkedEnvValues(newEnv);
            newEnv.environmentModel = deps.environmentModel;
            if (newEnv.pedestal !== "none") {
                newEnv.pedestal = deps.pedestal;
            }
            updatePedestal(newEnv);

            return newEnv;
        });
    }

    function setEnvModel(environmentModel: Environment) {
        setEnvironmentState((envState) => {
            if (!envState) return;
            const newEnv = {
                ...envState,
                environmentModel,
            };

            updatePedestal(newEnv);

            return newEnv;
        });
    }

    function setPedestal(usePed: boolean) {
        setEnvironmentState((envState) => {
            if (!envState) return;

            const newEnv = { ...envState };
            if (usePed) {
                newEnv.pedestal = getScaleLinkedEnvValues(newEnv).pedestal;
            } else {
                newEnv.pedestal = "none";
            }
            return newEnv;
        });
    }

    useEffect(() => {
        if (online && assetId && environmentState) {
            setEnvMetadata(
                {
                    environment: environmentState.environmentModel,
                    pedestal: environmentState.pedestal,
                    grounding: environmentState.groundModel,
                    centering: environmentState.centerModel,
                    upAxis: environmentState.upAxis,
                    scaling: environmentState.scaling.toString(),
                },
                assetId,
            );
        }
    }, [online, assetId, environmentState]);

    const fields = (
        <>
            <View position="relative">
                <LabeledValue label={t("envPanel.field.size.label")} value="" />
                <Chain size="XS" position="absolute" right={0} top={0} />
                <Flex justifyContent="space-between" wrap gap="size-125">
                    {environmentState &&
                        sizeUnits &&
                        environmentState.size.map((size, i) => (
                            <span key={sizeStrings[i]}>
                                <NumberField
                                    maxWidth="size-1200"
                                    hideStepper
                                    label={sizeStrings[i]}
                                    labelPosition="side"
                                    value={
                                        size *
                                        unitsMultiplier *
                                        environmentState?.scaling
                                    }
                                    onChange={(val) => {
                                        setScaling(val, size);
                                    }}
                                />
                                <LabeledValue
                                    marginStart="size-75"
                                    label={sizeUnits}
                                    value=""
                                />
                            </span>
                        ))}
                </Flex>
                {oversize && (
                    <View
                        marginTop="size-50"
                        UNSAFE_style={{
                            color: "var(--spectrum-semantic-negative-color-text-small, var(--spectrum-global-color-red-600))",
                        }}>
                        <Alert size="XS" marginEnd="size-50" />
                        <Text>{t("envPanel.field.unitScale.error")}</Text>
                    </View>
                )}
            </View>
            <Picker
                label={t("envPanel.field.unitScale.label")}
                width="100%"
                selectedKey={sizeUnits}
                onSelectionChange={(key) => setSizeUnits(key as any)}>
                <Item key="cm">
                    {t("envPanel.field.unitScale.value.centimeters")}
                </Item>
                <Item key="m">
                    {t("envPanel.field.unitScale.value.meters")}
                </Item>
            </Picker>
            <Picker
                label={t("envPanel.field.environment.label")}
                width="100%"
                selectedKey={environmentState?.environmentModel}
                validate={() => {
                    if (!environmentState) return null;
                    if (environmentState.environmentModel === "none") {
                        return true;
                    }
                    const expectedEnv =
                        getScaleLinkedEnvValues(
                            environmentState,
                        ).environmentModel;
                    return expectedEnv === environmentState.environmentModel
                        ? true
                        : t("envPanel.field.environment.error");
                }}
                onSelectionChange={(key) => setEnvModel(key as Environment)}>
                <Item key="none">
                    {t("envPanel.field.environment.value.none")}
                </Item>
                <Item key="studio">
                    {t("envPanel.field.environment.value.studio")}
                </Item>
                <Item key="garage">
                    {t("envPanel.field.environment.value.garage")}
                </Item>
                <Item key="courtyard">
                    {t("envPanel.field.environment.value.courtyard")}
                </Item>
            </Picker>
            <Checkbox
                UNSAFE_style={{ width: "max-content" }}
                isDisabled={isPedDisabled(largest)}
                isSelected={environmentState?.pedestal !== "none"}
                onChange={setPedestal}>
                {t("envPanel.field.pedestal.label")}
            </Checkbox>
            <Checkbox
                UNSAFE_style={{ width: "max-content" }}
                isSelected={environmentState?.groundModel === "grounded"}
                onChange={(checked) => {
                    setEnvironmentState({
                        ...environmentState!,
                        groundModel: checked ? "grounded" : "ungrounded",
                    });
                }}>
                {environmentState?.pedestal === "none"
                    ? t("envPanel.field.ground.label")
                    : t("envPanel.field.groundPedestal.label")}
            </Checkbox>
            <Checkbox
                UNSAFE_style={{ width: "max-content" }}
                isSelected={environmentState?.centerModel === "centered"}
                onChange={(checked) => {
                    setEnvironmentState({
                        ...environmentState!,
                        centerModel: checked ? "centered" : "uncentered",
                    });
                }}>
                {t("envPanel.field.center.label")}
            </Checkbox>
            <Picker
                label={t("envPanel.field.upAxis.label")}
                width="100%"
                selectedKey={environmentState?.upAxis}
                onSelectionChange={(key) =>
                    setEnvironmentState({
                        ...environmentState!,
                        upAxis: key as UpAxis,
                    })
                }>
                <Item key="y+">{t("envPanel.field.upAxis.value.yUp")}</Item>
                <Item key="y-">{t("envPanel.field.upAxis.value.yDown")}</Item>
                <Item key="z+">{t("envPanel.field.upAxis.value.zUp")}</Item>
                <Item key="z-">{t("envPanel.field.upAxis.value.zDown")}</Item>
                <Item key="x+">{t("envPanel.field.upAxis.value.xUp")}</Item>
                <Item key="x-">{t("envPanel.field.upAxis.value.xDown")}</Item>
            </Picker>
        </>
    );

    return (
        <View width="80vw" height="80vh" backgroundColor="gray-100">
            {modelUrl ? (
                <Flex direction="row" width="100%" height="100%">
                    <View width="65%">
                        <StudioView
                            modelUrl={modelUrl}
                            editorTools={[
                                StudioTool.cameraControls,
                                StudioTool.frameButton,
                            ]}
                            setSceneManager={setSceneManager}
                        />
                    </View>
                    <Flex
                        width="35%"
                        direction="column"
                        UNSAFE_style={{
                            padding:
                                "var(--spectrum-global-dimension-size-250)",
                            overflow: "auto",
                        }}>
                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            gap="size-125"
                            height="100%">
                            <View>
                                <Heading level={1} margin={0}>
                                    <TextField
                                        value={assetName}
                                        onChange={setAssetName}
                                    />
                                </Heading>
                                <Heading level={3}>
                                    {t("envPanel.title")}
                                </Heading>
                                <Flex
                                    direction="column"
                                    marginTop="size-300"
                                    gap="size-150">
                                    {fields}
                                </Flex>
                            </View>
                            <Flex
                                direction="column"
                                justifyContent="end"
                                alignItems="end">
                                <ButtonGroup marginBottom="size-200">
                                    <Button
                                        variant="secondary"
                                        onPress={cancel}>
                                        {t("common:actions.cancel")}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onPress={() =>
                                            homeModalRedirect(
                                                "preview",
                                                assetId,
                                            )
                                        }>
                                        {t("common:actions.back")}
                                    </Button>
                                    <Button
                                        variant="accent"
                                        isDisabled={oversize}
                                        onPress={() => confirmHandler()}
                                        autoFocus>
                                        {t("web:actions.next")}
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            ) : (
                <ProgressCircleView />
            )}
        </View>
    );
}
