/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PinEvents } from "@3di/adobe-3d-viewer";
import EventEmitter from "events";
import { v4 } from "uuid";

import { CommentsApiEvent } from "../comments-api/CommentsApi";

import type { CommentsApi } from "../comments-api/CommentsApi";
import type { SerializedPin, PinManager } from "@3di/adobe-3d-viewer";
import type {
    Comment,
    AnnotationEvent,
    CommentInteractionEvent,
} from "@ccx-public/ccx-comments";

enum SessionEvents {
    pinSelectionStarted = "pinSelectionStarted",
    pinSelectionEnded = "pinSelectionEnded",
}

export type PinSessionEvent = SessionEvents & PinEvents & CommentsApiEvent;
export class PinningSession extends EventEmitter {
    initialized = false;
    canceled = false;

    private temporaryPin?: SerializedPin;

    pins: SerializedPin[] = [];

    constructor(
        public pinManager: PinManager,
        private commentsApi: CommentsApi,
    ) {
        super();
        if (!commentsApi.initialized) {
            commentsApi.once(CommentsApiEvent.initialized, () =>
                this.checkReady(),
            );
        }
        if (!pinManager.initialized) {
            pinManager.once(PinEvents.pinManagerInitialized, () =>
                this.checkReady(),
            );
        }
        this.checkReady();
    }

    private checkReady() {
        if (this.pinManager.initialized && this.commentsApi.initialized) {
            this.addEvents();
            this.updatePins();
        }
    }

    async updatePins() {
        const pinnedComments = this.commentsApi.getCommentsWithPinData();
        const unresolvedComments = pinnedComments.filter((comment) => {
            return comment.status !== "resolved";
        });
        const pinsToUpdate = unresolvedComments
            .map((comment) => this.commentsApi.getPinDataForComment(comment.id))
            .filter((pinData) => {
                return pinData !== undefined;
            }) as SerializedPin[];
        if (this.temporaryPin) {
            pinsToUpdate.push(this.temporaryPin);
        }
        await this.pinManager.setPins(pinsToUpdate);
    }

    async addPin(commentId: string) {
        return new Promise<SerializedPin>((res, rej) => {
            this.pinManager.addPin(
                commentId,
                (pinData) => {
                    if (this.canceled) {
                        this.canceled = false;
                    } else {
                        this.temporaryPin = pinData;
                        this.commentsApi.setPendingPin(pinData);
                        res(pinData);
                    }
                },
                () => {
                    if (this.canceled) this.canceled = false;
                    this.commentsApi.cancelPendingPin();
                    rej();
                },
            );
        });
    }

    async updatePin(commentId: string) {
        return new Promise<SerializedPin>((res, rej) => {
            this.pinManager.updatePin(
                commentId,
                (pinData) => {
                    this.commentsApi.updatePinDataForComment(
                        commentId,
                        pinData,
                    );
                    res(pinData);
                },
                rej,
            );
        });
    }

    cancelPin() {
        this.canceled = true;
        this.pinManager.cancelAddOrUpdate();
        this.temporaryPin = undefined;
    }

    removePin(commentId: string) {
        // TODO remove from pin manager
        this.commentsApi.removePinDataForComment(commentId);
    }

    async filterPins(visibleComments: Comment[]) {
        const visiblePins = visibleComments
            .map((comment) => this.commentsApi.getPinDataForComment(comment.id))
            .filter((pinData) => {
                return pinData !== undefined;
            }) as SerializedPin[];
        await this.pinManager.setPins(visiblePins);
        const visiblePinIds: Set<string> = new Set();
        visibleComments.map((comment) => {
            const pin = this.commentsApi.getPinDataForComment(comment.id);
            if (pin?.id) {
                visiblePinIds.add(pin.id);
            }
        });
        this.pinManager.filterPins(visiblePinIds);
    }

    private addEvents() {
        this.pinManager
            .on(PinEvents.pinSelected, (e) => {
                this.emit(PinEvents.pinSelected, e);
                const pinId = e.pinData ? e.pinData.id : e.id;
                this.commentsApi.showComment(pinId);
            })
            .on(PinEvents.pinDeselected, (e) => {
                this.emit(PinEvents.pinDeselected, e);
                this.commentsApi.showComment();
            })
            .on(PinEvents.pinHoverStart, (pinData) => {
                this.emit(PinEvents.pinHoverStart, pinData);
                this.commentsApi.highlightComment(pinData.id);
            })
            .on(PinEvents.pinHoverEnd, (pinData) => {
                this.emit(PinEvents.pinHoverEnd, pinData);
                this.commentsApi.unhighlightComment();
            })
            .on(PinEvents.cameraMove, (data) => {
                this.emit(PinEvents.cameraMove, data);
            });

        this.commentsApi
            .on(CommentsApiEvent.commentsSuccess, (data) => {
                this.emit(CommentsApiEvent.commentsSuccess, data);
            })
            .on(CommentsApiEvent.commentsUpdate, (data) => {
                this.emit(CommentsApiEvent.commentsUpdate, data);
                if (
                    this.temporaryPin &&
                    data.delta.data.new &&
                    data.delta.data.new.length > 0
                ) {
                    this.temporaryPin = undefined;
                }
                this.updatePins();
            })
            .on(CommentsApiEvent.disableComments, () => {
                this.pinManager.setPins([]);
            })
            .on(CommentsApiEvent.enableComments, () => {
                this.updatePins();
            })
            .on(CommentsApiEvent.temporaryCommentConverted, () => {
                this.temporaryPin = undefined;
                this.updatePins();
            })
            .on(
                CommentsApiEvent.annotationChanged,
                (event: AnnotationEvent) => {
                    this.emit(CommentsApiEvent.annotationChanged, event);
                    if (event.trigger === "start") {
                        this.addPin(v4());
                        return;
                    }
                    if (event.trigger === "cancel") {
                        this.cancelPin();
                        this.updatePins();
                        return;
                    }
                    this.updatePins();
                },
            )
            .on(
                CommentsApiEvent.commentInteraction,
                (event: CommentInteractionEvent) => {
                    if (event.hasAnnotation && event.trigger === "hover") {
                        this.pinManager.highlightPin(event.id);
                    }
                    if (event.trigger === "unhover") {
                        this.pinManager.unhighlightPins();
                    }
                    if (event.trigger === "select") {
                        this.pinManager.selectPin(event.id);
                    }
                    this.emit(CommentsApiEvent.commentInteraction, event);
                },
            )
            .on(CommentsApiEvent.nodeChanged, (data) => {
                this.emit(CommentsApiEvent.nodeChanged, data);
                this.updatePins();
            })
            .on(CommentsApiEvent.commentsFiltered, (data) => {
                this.emit(CommentsApiEvent.commentsFiltered);
                this.filterPins(data);
            })
            .on(CommentsApiEvent.annotationToggle, () => {
                this.emit(CommentsApiEvent.annotationToggle);
                this.pinManager.togglePinsVisibility();
            });
    }

    private removeEvents() {
        this.pinManager
            .removeAllListeners(PinEvents.pinSelected)
            .removeAllListeners(PinEvents.pinDeselected)
            .removeAllListeners(PinEvents.pinHoverStart)
            .removeAllListeners(PinEvents.pinHoverEnd)
            .removeAllListeners(PinEvents.cameraMove);
        this.pinManager.dispose();
        this.commentsApi
            .removeAllListeners(CommentsApiEvent.commentsSuccess)
            .removeAllListeners(CommentsApiEvent.commentsUpdate)
            .removeAllListeners(CommentsApiEvent.temporaryCommentConverted)
            .removeAllListeners(CommentsApiEvent.annotationChanged)
            .removeAllListeners(CommentsApiEvent.commentInteraction)
            .removeAllListeners(CommentsApiEvent.nodeChanged);
        this.commentsApi.destroy();
    }

    dispose() {
        this.removeEvents();
    }
}
