/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionGroup,
    Item,
    Flex,
    Tabs,
    TabList,
    TabPanels,
    Text,
} from "@adobe/react-spectrum";
import ClassicGridView from "@spectrum-icons/workflow/ClassicGridView";
import ViewList from "@spectrum-icons/workflow/ViewList";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetsView } from "./AssetsView";
import { NoAssetsView, NoAssetsMessageType } from "./NoAssetsView";
import { SortAndFilterView } from "./SortAndFilterView";

import type { AssetMetadata } from "../contexts/AssetContext";
import type { Selection } from "@adobe/react-spectrum";
import type { Key } from "@react-types/shared";

export enum TabType {
    yourReviews = "yourReviews",
    sharedWithYou = "sharedWithYou",
}

enum LayoutType {
    grid = "grid",
    list = "list",
}

interface AssetTabsViewProps {
    accessToken: string | undefined;
    onAssetClick: (urn: string) => void;
    yourAssets: AssetMetadata[] | undefined;
    sharedWithYouAssets: AssetMetadata[] | undefined;
    isForVr: boolean;
    locale: string;
}

export function AssetTabsView({
    accessToken,
    onAssetClick,
    yourAssets,
    sharedWithYouAssets,
    isForVr,
    locale,
}: AssetTabsViewProps) {
    const { t } = useTranslation("common");
    const [layout, setLayout] = useState<Selection>(new Set([LayoutType.grid]));
    const [filteredAssets, setFilteredAssets] = useState<AssetMetadata[]>();

    const defaultTab = TabType.yourReviews;
    const [selectedTab, setSelectedTab] = useState<Key>();
    // this useEffect sets the default tab based on whether the user has uploaded assets, when assets have loaded
    useEffect(() => {
        if (selectedTab) return; // skip setting the default tab if a tab has already been explictly selected by the user
        if (!(yourAssets && sharedWithYouAssets)) return;
        if (yourAssets.length > 0) {
            setSelectedTab(TabType.yourReviews);
        } else if (sharedWithYouAssets.length > 0) {
            setSelectedTab(TabType.sharedWithYou);
        }
    }, [yourAssets, sharedWithYouAssets]);

    function getNoAssetsMessageType() {
        if (!accessToken) return NoAssetsMessageType.unauthorized;
        if (selectedTab === TabType.yourReviews) {
            if (yourAssets && yourAssets.length === 0)
                return NoAssetsMessageType.noReviews;
        } else {
            if (sharedWithYouAssets && sharedWithYouAssets.length === 0)
                return NoAssetsMessageType.noSharedReviews;
        }
        if (filteredAssets && filteredAssets.length === 0)
            return NoAssetsMessageType.noSearchResults;
        return NoAssetsMessageType.loading;
    }

    function assetsView(tabType: TabType) {
        const currentLayout =
            filteredAssets && filteredAssets.length === 0
                ? null
                : [...layout][0];
        const viewHeight = isForVr ? "65vh" : "calc(80vh - 420px)";

        return (
            <div style={{ overflow: "auto", height: viewHeight }}>
                {filteredAssets && filteredAssets.length > 0 ? (
                    <AssetsView
                        assets={filteredAssets}
                        onAssetClick={onAssetClick}
                        gridLayoutEnabled={currentLayout === LayoutType.grid}
                        tabType={tabType}
                        isForVr={isForVr}
                        locale={locale}
                    />
                ) : (
                    <NoAssetsView
                        messageType={getNoAssetsMessageType()}
                        isForVr={isForVr}
                    />
                )}
            </div>
        );
    }

    return (
        <Tabs selectedKey={selectedTab || defaultTab} onSelectionChange={setSelectedTab}>
            <Flex direction="row" justifyContent="space-between">
                <TabList minWidth={isForVr ? "288px" : ""}>
                    <Item key={TabType.yourReviews} aria-label={t("tabs.yourReviews")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isForVr ? "1.6em" : "",
                            }}>
                            {t("tabs.yourReviews")}
                        </Text>
                    </Item>
                    <Item key={TabType.sharedWithYou} aria-label={t("tabs.shared")}>
                        <Text
                            UNSAFE_style={{
                                fontSize: isForVr ? "1.6em" : "",
                            }}>
                            {t("tabs.shared")}
                        </Text>
                    </Item>
                </TabList>
                <ActionGroup
                    selectionMode="single"
                    selectedKeys={layout}
                    onSelectionChange={setLayout}
                    disallowEmptySelection={true}
                    isQuiet
                    alignSelf="center">
                    <Item key="list" aria-label={t("accessibility.tabs.toggleList")}>
                        <ViewList />
                    </Item>
                    <Item key="grid" aria-label={t("accessibility.tabs.toggleGrid")}>
                        <ClassicGridView />
                    </Item>
                </ActionGroup>
            </Flex>
            <TabPanels>
                <Item key={TabType.yourReviews} aria-label={t("tabs.yourReviews")}>
                    <SortAndFilterView
                        yourAssets={yourAssets}
                        sharedWithYouAssets={sharedWithYouAssets}
                        filteredAssets={filteredAssets}
                        selectedTab={selectedTab || defaultTab}
                        setFilteredAssets={setFilteredAssets}
                        isForVr={isForVr}
                    />
                    {assetsView(TabType.yourReviews)}
                </Item>
                <Item key={TabType.sharedWithYou} aria-label={t("tabs.shared")}>
                    <SortAndFilterView
                        yourAssets={yourAssets}
                        sharedWithYouAssets={sharedWithYouAssets}
                        filteredAssets={filteredAssets}
                        selectedTab={selectedTab || defaultTab}
                        setFilteredAssets={setFilteredAssets}
                        isForVr={isForVr}
                    />
                    {assetsView(TabType.sharedWithYou)}
                </Item>
            </TabPanels>
        </Tabs>
    );
}
