/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    ProgressCircle,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

export function ProgressCircleView({ isBlocked=false }: { isBlocked?: boolean }) {
    const { t } = useTranslation("common");

    return (
        <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center">
            {isBlocked ?
                <ProgressCircle
                    aria-label={t("accessibility.progress.loading")}
                    isIndeterminate
                    staticColor="white"
                />
                :
                <ProgressCircle
                    aria-label={t("accessibility.progress.loading")}
                    isIndeterminate
                />}
        </Flex>
    );
}
