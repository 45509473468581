/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    DialogContainer,
    Item,
    Menu,
    MenuTrigger,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import More from "@spectrum-icons/workflow/More";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetRenameDialog } from "./AssetRenameDialog";
import { useAssetContext } from "../contexts/AssetContext";
import noThumbnail from "../images/no-thumbnail.svg";

import type { AssetMetadata } from "../contexts/AssetContext";

interface AssetActionMenuProps {
    asset: AssetMetadata;
    isForVr: boolean;
}

export function AssetActionMenu({ asset, isForVr }: AssetActionMenuProps) {
    const { t } = useTranslation("common");
    const menuItems = [
        { name: t("actions.rename") },
        { name: t("actions.delete") },
    ];
    const [isRenamingAsset, setIsRenamingAsset] = useState<boolean>(false);

    const { deleteAsset } = useAssetContext();

    function toggleRenameDialog(isShowing: boolean) {
        setIsRenamingAsset(isShowing);
    }

    async function handleDelete(urn: string) {
        const isSuccessful = await deleteAsset(urn, true);
        if (isSuccessful) {
            ToastQueue.positive(t("toast.delete.successful"), { timeout: 5000 });
        } else {
            ToastQueue.negative(t("toast.delete.fail"), { timeout: 5000 });
        }
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <MenuTrigger align="end">
                <ActionButton isQuiet>
                    <More />
                </ActionButton>
                <Menu
                    items={menuItems}
                    onAction={(key) => {
                        switch (key) {
                            case t("actions.rename"):
                                setIsRenamingAsset(true);
                                break;
                            case t("actions.delete"):
                                handleDelete(asset.urn);
                                break;
                        }
                    }}>
                    {(item) => <Item key={item.name} aria-label={item.name}>{item.name}</Item>}
                </Menu>
            </MenuTrigger>
            <DialogContainer onDismiss={() => toggleRenameDialog(false)}>
                {isRenamingAsset && (
                    <AssetRenameDialog
                        urn={asset.urn}
                        displayName={asset.displayName}
                        thumbnailUrl={
                            asset.thumbnailUrl
                                ? asset.thumbnailUrl
                                : noThumbnail
                        }
                        isForVr={isForVr}
                        toggleRenameDialog={toggleRenameDialog}
                    />
                )}
            </DialogContainer>
        </div>
    );
}
