/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ToastQueue } from "@react-spectrum/toast";
import { JobClient, type JobsApiConfig } from "@shared/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { HI5_API_KEY, ASSET_APIS } from "../config";
import { useAssetContext } from "@src/contexts/AssetContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { getConversionSpec, getThumbnailSpec } from "@src/util/JobSpecs";
import {
    CreateCompositeResult,
    createAndPushComposite,
} from "@src/util/UploadUtils";

const apiConfig: JobsApiConfig = {
    url: ASSET_APIS.substance3d,
    key: HI5_API_KEY,
};

export function useUploadAsset() {
    const { t } = useTranslation(["common", "web"]);
    const { accessToken } = useHi5UserContext();

    const [isUploading, setIsUploading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [processingProgress, setProcessingProgress] = useState<number>(0);
    const [uploadCancel, setUploadCancel] = useState<() => void>();
    const [processCancel, setProcessCancel] = useState<() => Promise<void>>();

    const {
        cloudContentAssetId,
        cloudContentRepositoryId,
        yourAssets,
        service,
        session,
        onAssetUploadCompleted,
        onGlbConversionCompleted,
        onThumbnailUploadCompleted,
        handleDuplicateNames,
        storageQuotaData,
        deleteAsset,
    } = useAssetContext();

    function resetUpload() {
        setIsUploading(false);
        setUploadProgress(0);
        setUploadCancel(undefined);
    }

    function resetProcessing() {
        setIsProcessing(false);
        setProcessingProgress(0);
        setProcessCancel(undefined);
    }

    async function uploadAsset(file: File) {
        if (!accessToken) return;
        if (storageQuotaData && storageQuotaData.percentageUsed > 99) {
            ToastQueue.negative(
                t("web:toast.upload.uploadFailed.storageFull"),
                {
                    actionLabel: t("web:actions.upgrade"),
                    onAction: () =>
                        window.open(t("web:actions.upgrade.link"), "_blank"),
                },
            );
            return;
        }
        const uploadProgressCallback = (startBuf: number, endBuf: number) => {
            setUploadProgress((startBuf / endBuf) * 100);
        };

        setIsUploading(true);
        const upload = await createAndPushComposite(
            service,
            session,
            file,
            cloudContentAssetId,
            cloudContentRepositoryId,
            uploadProgressCallback,
            yourAssets,
            handleDuplicateNames,
        );

        setUploadCancel(() => () => {
            upload?.cancel();
            if (upload?.compositeId) {
                deleteAsset(upload?.compositeId);
            }
        });

        upload?.results.then(() => {
            resetUpload();
        });

        return upload;
    }

    async function processUpload(uploadData: CreateCompositeResult) {
        if (uploadData && accessToken) {
            setIsProcessing(true);
            const jobs: JobClient[] = [];

            const thumbJob = new JobClient(
                accessToken,
                apiConfig,
                getThumbnailSpec(
                    accessToken,
                    uploadData.assetId,
                    uploadData.componentId,
                    uploadData.componentVersion,
                    uploadData.originalExtension,
                ),
            );

            thumbJob.jobCompletePromise.then(() => {
                onThumbnailUploadCompleted(uploadData.assetId);
            })

            if (uploadData.originalExtension !== ".glb") {
                const convertJob = new JobClient(
                    accessToken,
                    apiConfig,
                    getConversionSpec(
                        accessToken,
                        uploadData.assetId,
                        uploadData.componentId,
                        uploadData.componentVersion,
                        uploadData.originalExtension,
                    ),
                );
                convertJob.jobCompletePromise.then(() => {
                    onGlbConversionCompleted(uploadData.assetId);
                }).catch(() => {
                    ToastQueue.negative(t("web:toast.upload.error.fileConversion"))
                })
                jobs.push(
                    convertJob,
                );
            }

            setProcessCancel(() => async () => {
                await Promise.all(jobs.map((job) => job.cancel()));
                deleteAsset(uploadData.assetId);
            });

            const updateProgress = () => {
                const progress =
                    jobs.reduce((acc, val) => acc + val.progress, 0) /
                    jobs.length;
                setProcessingProgress(progress);
            };

            jobs.forEach((job) => job.on("progress", updateProgress));

            await Promise.all(jobs.map((job) => job.jobCompletePromise));

            jobs.forEach((job) => job.off("progress", updateProgress));

        } else {
            ToastQueue.negative(t("web:toast.upload.uploadFailed"), {
                timeout: 5000,
            });
        }
        resetProcessing();
    }

    async function uploadAndProcessAsset(
        file: File,
        onComplete: (assetId?: string, componentId?: string) => void,
    ) {
        window.onbeforeunload = () =>
            "You are still uploading would you like to cancel?";

        const upload = await uploadAsset(file);

        if (upload) {
            const uploadData = await upload.results;
            if (uploadData) {
                onAssetUploadCompleted(
                    uploadData.assetId,
                    uploadData.originalExtension,
                );
                await processUpload(uploadData);
                onComplete(uploadData.assetId, uploadData.componentId);
            }
        }

        window.onbeforeunload = null;

        onComplete();
    }

    async function cancel() {
        if (isUploading) {
            uploadCancel?.();
            resetUpload();
        }
        if (isProcessing) {
            await processCancel?.();
            resetProcessing();
        }
        window.onbeforeunload = null;
    }

    return {
        isUploading,
        isProcessing,
        uploadProgress,
        processingProgress,
        uploadAsset,
        processUpload,
        uploadAndProcessAsset,
        cancel,
    };
}
