/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Flex,
    Heading,
    Image,
    Item,
    Picker,
    Switch,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useMessagingContext } from "@shared/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MicrophoneIcon from "../images/MicUnmuteUpdate.svg";
import { PHOTON_MAX_PLAYERS } from "@src/config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import type { NetworkContextValue } from "@src/contexts/NetworkContext";
import { useAudioControls } from "@src/hooks/useAudioControls";
import { useRedirects } from "@src/hooks/useRedirects";

import type { AssetContextValue } from "../contexts/AssetContext";

interface Props {
    assetId?: string;
    assetContext: AssetContextValue;
    assetName: string;
    isYourAsset?: boolean;
    networkContext: NetworkContextValue | null;
    twoUp?: boolean;
}

export function EnterReviewDialog({
    assetId,
    assetContext,
    assetName,
    isYourAsset = true,
    networkContext,
    twoUp = false,
}: Props) {
    const { t } = useTranslation(["common"]);
    const networkManager = networkContext?.networkManager;
    const { closeModal } = useMessagingContext();
    const { homeRedirect, reviewRedirect } = useRedirects();
    const { avatarUrl, userProfile } = useHi5UserContext();
    const { online, getThumbnail } = assetContext;
    const [modelThumbnail, setModelThumbnail] = useState("");
    const [playerCount, setPlayerCount] = useState(0);
    const [joinedLobby, setJoinedLobby] = useState(false);

    const {
        audioDevices,
        currentAudioDeviceId,
        setCurrentAudioDeviceId,
        muted,
        setMuted,
    } = useAudioControls();

    useEffect(() => {
        if (!networkManager || !assetId) return;

        const initRealtimeConnection = async () => {
            try {
                await networkManager.startConnection(assetId);
                if (networkManager.isJoinedLobby()) {
                    setJoinedLobby(true);
                }
            } catch (error) {
                console.error("Error initializing realtime:", error);
            }
        };

        initRealtimeConnection();
        setPlayerCount(networkManager.getPlayerCountInRoom(assetId));
    }, [networkManager]);

    useEffect(() => {
        if (assetId && (online || twoUp)) {
            (async () => {
                const thumbnailUrl = await getThumbnail(assetId, isYourAsset);
                if (thumbnailUrl) {
                    setModelThumbnail(thumbnailUrl);
                }
            })();
        }
    }, [assetId, online]);

    useEffect(() => {
        const handleOnRoomListUpdate = () => {
            if (networkManager && assetId) {
                setPlayerCount(networkManager.getPlayerCountInRoom(assetId));
            }
        };

        window.addEventListener("onRoomListUpdate", handleOnRoomListUpdate);

        return () => {
            window.removeEventListener(
                "onRoomListUpdate",
                handleOnRoomListUpdate,
            );
        };
    }, []);

    function formatPlayerCountString() {
        if (playerCount != 1) {
            return (
                t("enterReview.playerCount.plural.1") +
                playerCount +
                t("enterReview.playerCount.plural.2")
            );
        } else {
            return (
                t("enterReview.playerCount.singular.1") +
                playerCount +
                t("enterReview.playerCount.singular.2")
            );
        }
    }

    return (
        <View
            maxWidth="700px"
            UNSAFE_style={{ height: "min-content" }}
            backgroundColor="gray-100"
            borderRadius="large"
            padding="size-400">
            <Flex direction="column" width="100%" height="100%">
                <Heading level={1} margin={0} marginBottom="size-125">
                    {assetName} &nbsp;
                </Heading>
                <Flex gap="size-400">
                    <View
                        minWidth="360px"
                        height="360px"
                        borderRadius="large"
                        backgroundColor="gray-200">
                        <Image
                            src={modelThumbnail}
                            isHidden={!modelThumbnail}
                        />
                    </View>
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        width="320px">
                        <View>
                            {playerCount > 0 && (
                                <>
                                    <View
                                        backgroundColor="gray-400"
                                        padding="size-100"
                                        borderRadius="regular">
                                        <Text>{formatPlayerCountString()}</Text>
                                    </View>
                                    <View paddingTop="5px" paddingBottom="10px">
                                        {PHOTON_MAX_PLAYERS != 0 &&
                                            playerCount >=
                                                PHOTON_MAX_PLAYERS && (
                                                <Text
                                                    UNSAFE_style={{
                                                        fontSize: "0.9em",
                                                        color: "var(--spectrum-semantic-negative-color-text-small, var(--spectrum-global-color-red-600))",
                                                    }}>
                                                    {t(
                                                        "enterReview.playerCount.max",
                                                    )}
                                                </Text>
                                            )}
                                    </View>
                                </>
                            )}

                            <Flex direction="column" gap="size-250">
                                <Picker
                                    width="100%"
                                    label="Select microphone"
                                    selectedKey={currentAudioDeviceId}
                                    onSelectionChange={(deviceId) =>
                                        setCurrentAudioDeviceId(
                                            deviceId as string,
                                        )
                                    }>
                                    {audioDevices.map((device) => (
                                        <Item key={device.deviceId}>
                                            {device.label}
                                        </Item>
                                    ))}
                                </Picker>
                                <Flex gap="size-125">
                                    <img
                                        src={MicrophoneIcon}
                                        height="18px"
                                        style={{
                                            marginTop: "6px",
                                            display: "block",
                                        }}
                                    />
                                    <Switch
                                        isSelected={!muted}
                                        onChange={(selected) =>
                                            setMuted(!selected)
                                        }
                                    />
                                </Flex>
                            </Flex>
                        </View>
                        <Flex direction="column" gap="size-300">
                            <Flex gap="size-150">
                                <Image
                                    width="48px"
                                    height="48px"
                                    src={avatarUrl}
                                />
                                <Flex direction="column">
                                    <Heading level={2} margin={0}>
                                        {userProfile?.displayName ||
                                            `${userProfile?.first_name} ${userProfile?.last_name}`}
                                    </Heading>
                                    <Text
                                        UNSAFE_style={{
                                            textOverflow: "ellipsis",
                                            overflowX: "hidden",
                                            maxWidth: "100%",
                                            opacity: 0.7,
                                        }}>
                                        {userProfile?.email}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex justifyContent="space-between" gap="size-250">
                                <Button
                                    flex={1}
                                    variant="secondary"
                                    onPress={() => {
                                        homeRedirect();
                                    }}>
                                    {t("navigation.goHome")}
                                </Button>
                                <Button
                                    flex={1}
                                    variant="cta"
                                    onPress={() => {
                                        closeModal();
                                        reviewRedirect(assetId!);
                                    }}
                                    isDisabled={
                                        (PHOTON_MAX_PLAYERS != 0 &&
                                            playerCount >=
                                                PHOTON_MAX_PLAYERS) ||
                                        !joinedLobby
                                    }>
                                    {t("navigation.enterReview")}
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </View>
    );
}
