/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { GraphSpec, ModelMediaExtensionsUsd } from "@shared/types";


export function getConversionSpec(
    accessToken: string,
    assetId: string,
    componentId: string,
    componentVersion: string,
    originalExtension: string
) {
    const spec: GraphSpec = {
        getModel: {
            type: "https.acp.component.download",
            output: {
                type: "model",
                extension: originalExtension as ModelMediaExtensionsUsd,
            },
            parameters: {
                assetId: assetId,
                componentId: componentId,
                version: componentVersion,
                imsToken: accessToken,
            },
        },
    };

    if (originalExtension !== "usdz") {
        spec.convertUsdz = {
            type: "model.convert",
            inputs: { model: "getModel" },
            output: {
                type: "model",
                extension: ".usdz",
            },
        };
        spec.uploadConvertedUsdz = {
            type: "https.acp.component.upload",
            inputs: {
                file: "convertUsdz",
            },
            parameters: {
                imsToken: accessToken,
                assetId: assetId,
                componentName: "convertedUsdzModel",
                componentPath: "renditions/convertedUsdzModel.usdz",
            },
        };
    }

    if (originalExtension !== "glb") {
        spec.convertGlb = {
            type: "model.convert",
            inputs: { model: "getModel" },
            output: {
                type: "model",
                extension: ".glb",
            },
        };
        spec.uploadConvertedGlb =  {
            type: "https.acp.component.upload",
            inputs: {
                file: "convertGlb",
            },
            parameters: {
                imsToken: accessToken,
                assetId: assetId,
                componentName: "convertedGlbModel",
                componentPath: "renditions/convertedGlbModel.glb",
            },
        };
    }

    return spec
}


const THUMBNAIL_WIDTH = 256;
const THUMBNAIL_HEIGHT = 256;
const THUMBNAIL_SAMPLES = 16;

export function getThumbnailSpec(
    accessToken: string,
    assetId: string,
    componentId: string,
    componentVersion: string,
    originalExtension: string,
) {
    const spec: GraphSpec = {
            getModel: {
                type: "https.acp.component.download",
                output: {
                    type: "model",
                    extension: originalExtension as ModelMediaExtensionsUsd,
                },
                parameters: {
                    assetId: assetId,
                    componentId: componentId,
                    version: componentVersion,
                    imsToken: accessToken,
                },
            },
            render: {
                type: "model.render",
                inputs: {
                    model: "getModel",
                },
                output: {
                    type: "image",
                    extension: ".png",
                },
                parameters: {
                    resolution: {
                        width: THUMBNAIL_WIDTH,
                        height: THUMBNAIL_HEIGHT,
                    },
                    backgroundColor: {
                        red: 0.0,
                        green: 0.0,
                        blue: 0.0,
                        alpha: 0.0,
                    },
                    samples: THUMBNAIL_SAMPLES,
                    gpu: false,
                    denoise: true,
                    groundplane: false,
                    stripLights: true,
                },
            },
            upload: {
                type: "https.acp.component.upload",
                inputs: {
                    file: "render",
                },
                parameters: {
                    imsToken: accessToken,
                    assetId: assetId,
                    componentName: "thumbnail",
                    componentPath: "renditions/image-256-256.png",
                    componentRelationship: "rendition",
                },
            },
    };

    return spec;
}
