/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { type BackgroundFetch, LRUCache } from "lru-cache";

export class SerializedLruCache<K extends {}, V extends {}, FC = unknown> extends LRUCache<K, V, FC>{
    private storageKey: string
    constructor (storageKey: string, options: LRUCache.Options<K, V, FC>) {
        super(options);
        this.storageKey = storageKey;
        this.hydrate();
    }

    set(k: K, v: V | BackgroundFetch<V> | undefined, setOptions?: LRUCache.SetOptions<K, V, FC>) {
        super.set(k, v, setOptions);
        if (v instanceof Promise) {
            v.then(() => {
                this.serialize()
            })
        } else {
            this.serialize();
        }
        return this;
    }

    private getSavedCacheKey () {
        return `lru-cache-${this.storageKey}`
    }

    private hydrate() {
        try {
            // const savedCache = window.localStorage.getItem(this.getSavedCacheKey());
            // if (savedCache) {
            //     const cache = JSON.parse(savedCache);
            //     this.load(cache);
            // }
        } catch(e) {
            console.warn(`Failed to hydrate saved cache ${this.storageKey}`)
        }
    }

    private serialize() {
        try {
            // const dump = JSON.stringify(this.dump());
            // window.localStorage.setItem(this.getSavedCacheKey(), dump);
        } catch (e) {
            console.error("Failed to serialize and set cache", {dump: this.dump(), key: this.storageKey});
        }
    }
}
