/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    getPresignedUrl,
    LinkRelation,
    parseLinksFromResponseHeader,
} from "@dcx/assets";
import {
    createRepoAPISession,
    pullCompositeManifestOnly,
    newDCXComposite,
    pushComposite,
} from "@dcx/dcx-js";
import { createHTTPService } from "@dcx/http";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { EventType, useAnalytics } from "../hooks/useAnalytics";
import { getReviewPath } from "../util/PathUtils";
import { ADOBE_IMS_CONFIG, ASSET_APIS, baseUrl } from "@src/config";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import type { Environment, Pedestal } from "@src/scene/EnvironmentBuilder";
import { getEnvUrl, getPedestalUrl } from "@src/scene/EnvironmentBuilder";
import { SerializedLruCache } from "@src/util/SerializedLruCache";

import type { AssetTechInfo } from "../util/HealthCheckUtils";
import type {
    AdobeDCXBranch,
    AdobeDCXComposite,
    AdobeHTTPService,
    AdobeRepoAPISession,
} from "@dcx/dcx-js";

type ProviderProps = { children: React.ReactNode };

export type AssetFileData = {
    name?: string;
    url?: string;
    urn?: string;
    file: File;
    techInfo: AssetTechInfo | undefined;
};

export type AssetMetadata = {
    urn: string;
    displayName: string;
    createdBy?: string;
    modifyDate?: string;
    manifestUrl?: string;
    applicationMetadataUrl?: string;
    collaborators?: number;
    thumbnailUrl?: string;
    isBlocked?: boolean;
};

export type ComponentData = {
    componentId: string;
    componentVersion: string;
    thumbnailId: string;
    thumbnailVersion: string;
    usdzComponentId?: string;
    usdzComponentVersion?: string;
    originalFormat: string;
};

export type GuestDcxData = {
    service: AdobeHTTPService;
    session: AdobeRepoAPISession;
    composite: AdobeDCXComposite;
    manifest: AdobeDCXBranch;
};

export type StorageQuotaData = {
    bytesLimit: number;
    bytesUsed: number;
    percentageUsed: number;
};

export type AssetContextValue = {
    online: boolean;
    service: AdobeHTTPService | undefined;
    session: AdobeRepoAPISession | undefined;
    assetUrn: string;
    cloudContentAssetId: string;
    cloudContentRepositoryId: string;
    cloudContentApplicationMetadataUrl: string;
    storageQuotaData?: StorageQuotaData;
    assetMetadata?: AssetMetadata;
    assetComponentData?: ComponentData;
    yourAssets?: Array<AssetMetadata>;
    sharedWithYouAssets?: Array<AssetMetadata>;
    assetDownloadUrl: string;
    userAccessResponseStatus: number | undefined;
    guestAccessToken: string;
    yourAssetsHasChange: boolean;
    brokenAssetUrn: string;
    setAssetUrn: (urn: string) => void;
    checkUserAccess: (urn: string, accessToken: string) => Promise<number | undefined>;
    requestAccess: () => Promise<number | undefined>;
    setYourAssets: (yourAssets: AssetMetadata[]) => void;
    setYourAssetsHasChange: (hasChange: boolean) => void;
    setSharedWithYouAssets: (sharedWithYouAssets: AssetMetadata[]) => void;
    setSharedWithYouAssetsHasChange: (hasChange: boolean) => void;
    getApplicationMetadata: (applicationMetadataUrl: string) => any;
    updateApplicationMetadata: (
        applicationMetadataUrl: string,
        path: string,
        value: string,
    ) => void;
    getStorageQuota: () => void;
    getMetadata: (
        urn: string,
        shouldSet?: boolean,
    ) => Promise<AssetMetadata | undefined>;
    getComponentData: (
        urn: string,
        shouldSet?: boolean,
        isPoll?: boolean,
    ) => Promise<ComponentData | undefined>;
    getThumbnail: (
        urn: string,
        isYourAsset: boolean,
    ) => Promise<string | undefined>;
    getDownloadUrl: (
        urn: string,
        format?: string,
    ) => Promise<string | undefined>;
    getAssetAsBlob: (
        urn: string,
        assetDownloadUrl: string,
    ) => Promise<Blob | undefined>;
    getCachedBlobUrl: (urn: string) => string | undefined;
    getYourAssets: (
        shouldSet: boolean | undefined,
    ) => Promise<AssetMetadata[] | undefined>;
    getSharedWithYouAssets: (
        shouldSet: boolean | undefined,
    ) => Promise<AssetMetadata[] | undefined>;
    getDiscardedAssets: () => Promise<AssetMetadata[] | undefined>;
    onAssetUploadCompleted: (urn: string, originalExtension: string) => void;
    onGlbConversionCompleted: (urn: string) => void;
    onThumbnailUploadCompleted: (urn: string) => void;
    handleDuplicateNames: (fileName: string) => string;
    renameAsset: (
        urn: string,
        sourceName: string,
        targetName: string,
    ) => Promise<boolean | undefined>;
    deleteAsset: (
        urn: string,
        shouldSet?: boolean,
    ) => Promise<boolean | undefined>;
    discardAsset: (urn: string) => Promise<boolean | undefined>;
    restoreAsset: (urn: string) => Promise<boolean | undefined>;
    setEnvMetadata: (meta: ReviewerEnvMeta, assetId?: string) => Promise<void>;
    getEnvMetadata: (
        assetId?: string,
        tokensToUrls?: boolean,
    ) => Promise<ReviewerEnvMeta | undefined>;
};

export const AssetContext = createContext<AssetContextValue>(
    {} as AssetContextValue,
);

export const useAssetContext = () => {
    const context = useContext(AssetContext);
    if (!context.setAssetUrn) {
        throw new Error("useAssetContext must be used within a AssetProvider.");
    }
    return context;
};

const REVIEWER_EVN_META_VERSION = 0;

const reviewerMetaFields = [
    "metaFormatVersion",
    "units",
    "environment",
    "scaling",
    "physicalSize",
    "pedestal",
    "upAxis",
    "grounding",
    "centering",
] as const;

export type ReviewerEnvMetaKeys = (typeof reviewerMetaFields)[number];
export type ReviewerEnvMeta = Partial<Record<ReviewerEnvMetaKeys, string>>;

const TTL_MAX_URL_MS = 1 * 60 * 60 * 1000; // hours to milliseconds (ACP URL expiry time seems to be set to 4 hours)
const TTL_MAX_REQUEST_MS = 10 * 60 * 1000; // 10 minutes
const urlCache = new SerializedLruCache<string, string>("acp-url-cache", {
    max: 10_000,
    ttl: TTL_MAX_URL_MS,
});

const requestCache = new SerializedLruCache<string, string>(
    "acp-request-cache",
    {
        max: 10_000,
        ttl: TTL_MAX_REQUEST_MS,
    },
);

async function hash(obj: object) {
    const encoder = new TextEncoder();
    const buffer = await window.crypto.subtle.digest(
        "SHA-256",
        encoder.encode(JSON.stringify(obj)),
    );
    return btoa(
        String.fromCharCode.apply(
            null,
            new Uint8Array(buffer) as unknown as number[],
        ),
    );
}

async function cachedFetchJson<T = any>(
    url: string,
    options?: Parameters<typeof fetch>[1],
) {
    const cacheKey = await hash({ url, options });
    const cachedResponse = requestCache.get(cacheKey);
    if (cachedResponse) {
        return JSON.parse(cachedResponse) as T;
    }
    const res = await fetch(url, options);
    try {
        const json = (await res.json()) as T;
        if (json) {
            requestCache.set(cacheKey, JSON.stringify(json));
        }
        return json;
    } catch (e) {
        console.error("Invalid response");
        return;
    }
}

async function deleteCache(url: string, options?: Parameters<typeof fetch>[1]) {
    const cacheKey = await hash({ url, options });
    requestCache.delete(cacheKey);
}

export const AssetProvider = ({ children }: ProviderProps): any => {
    const { accessToken } = useHi5UserContext();
    const { sendLaunchAnalytics } = useAnalytics();

    const [assetUrn, setAssetUrn] = useState<string>("");
    const [online, setOnline] = useState(false);

    const [yourAssetsThumbnails, setYourAssetsThumbnails] =
        useState<Record<string, string | undefined>>();
    const [sharedWithYouAssetsThumbnails, setSharedWithYouAssetsThumbnails] =
        useState<Record<string, string | undefined>>();

    function defaultRequestOptions(method: string): RequestInit {
        const options: RequestInit = {
            method,
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "Content-Type": "application/json",
            },
        };
        return options;
    }

    const [service, setService] = useState<AdobeHTTPService | undefined>();
    const [session, setSession] = useState<AdobeRepoAPISession | undefined>();

    useEffect(() => {
        if (accessToken) {
            startService();
            getCloudDocuments();
        }
    }, [accessToken]);

    function startService() {
        if (!accessToken) {
            console.error("error starting upload service - no accessToken");
            return;
        }
        const newService: AdobeHTTPService = createHTTPService((s) => {
            s?.setApiKey(ADOBE_IMS_CONFIG.client_id);
            s?.setAuthToken(accessToken);
        });
        const newSession = createRepoAPISession(newService, ASSET_APIS.hostAcp);
        setService(newService);
        setSession(newSession);
        console.log("Started service");
    }

    const [userAccessResponseStatus, setUserAccessResponseStatus] = useState<
        number | undefined
    >(undefined);
    async function checkUserAccess(urn: string, accessToken: string) {
        if (accessToken === undefined && urn) {
            const guestHasAccess = await checkGuestAccess(urn);
            if (guestHasAccess) {
                setUserAccessResponseStatus(1);
                return 1;
            } else {
                setUserAccessResponseStatus(0);
                return 0;
            }
        }

        if (!(accessToken && urn)) return;
        const response = await fetch(
            `${ASSET_APIS.invitations}/auth/${urn}`,
            defaultRequestOptions("GET"),
        );
        setUserAccessResponseStatus(response.status);
        return response.status;
    }

    async function requestAccess() {
        if (!(accessToken && assetUrn)) return;
        const options = defaultRequestOptions("POST");
        options.body = JSON.stringify({
            notification: {
                email: {
                    locale: "en-US",
                    templateName: "cc_collab_clouddoc_request_access",
                },
                parameters: {
                    targetUrl: `${baseUrl}${getReviewPath(assetUrn)}&share=true`,
                },
            },
            resourceUrn: assetUrn,
            requestedPermissions: {
                canComment: true,
                canShare: true,
                role: "editor",
            },
        });
        const response = await fetch(
            `${ASSET_APIS.invitations}/accessrequests`,
            options,
        );
        return response.status;
    }

    const [guestAccessToken, setGuestAccessToken] = useState<string>("");
    /**
     * Referenced from example at https://git.corp.adobe.com/DMA/dcx-js-demo/blob/main/src/workflows/invitationService.ts#L82-L89
     * @param session AdobeRepoAPISession
     * @param url string - the invitation url
     * @returns {AdobeDCXComposite} Composite populated with links from the response header of invitation auth.
     */
    async function authCompositeAsGuest(
        newSession: AdobeRepoAPISession,
        url: string,
    ) {
        const newService = newSession.serviceConfig.service;

        // Method to retrieve/refresh access token and composite links
        async function _authGuestAssetInvitation() {
            const response = await newService.invoke("GET", url);
            const token = response.response.accessToken;
            // Assign the access token from the response to all future requests emitted by the HTTPService
            newService.setAdditionalHeaders({
                "x-access-token": token,
            });
            setGuestAccessToken(token);
            return parseLinksFromResponseHeader(response);
        }

        // ensure x-api-key is not stripped for requests to the cdn
        newService.authenticationAllowList.push("adobecc.com");
        newService.setApiKey(ADOBE_IMS_CONFIG.client_id);

        // Authenticate, instantiate new local composite with links provided.
        const composite = newDCXComposite(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            await _authGuestAssetInvitation(),
        );

        // On token expiration the CDN returns 410, we must refresh the auth token (and potentially links) before retrying.
        newService.setRetryOptions({
            // Retry on 5xx (except 501 and 507), 429, 423, and 410 status codes
            retryCodes: [/^(?!^501$|^507$)^(5\d{2})$|429|423|410$/],
            preCallback: async function refreshAuthOn410(_xhr, backoff) {
                const previousRequest =
                    backoff?.requests[backoff?.requests.length - 1];
                // attempt to re-authenticate on 410 ONLY, refresh composite cached links
                if (previousRequest && previousRequest.getStatus() === 410) {
                    // refresh local composite links when token is refreshed.
                    composite.links = await _authGuestAssetInvitation();
                }
            },
        });

        // Since we attached the x-access-token header to all requests sent by the service,
        // we should also remove it whenever other authentication headers are being removed
        // In practice, this should only happen if one of the links points to somewhere
        // outside of the urls in the allowlist (and not the CDN or invitation-service)
        // or if the HTTPService is also used outside of this workflow.
        newService.setRequestHooks(
            function removeAccessTokenFromUnauthorizedRequests(xhr) {
                if (
                    !xhr ||
                    !xhr.headers["x-access-token"] ||
                    newService.authProvider.isAuthorizedURL(xhr.href)
                ) {
                    // just continue if there is no action to be taken right now.
                    return;
                }
                delete xhr.headers["x-access-token"];
            },
        );

        return composite;
    }

    const [guestDcxData, setGuestDcxData] = useState<GuestDcxData>();
    async function checkGuestAccess(urn: string) {
        const newService = createHTTPService();
        const newSession = createRepoAPISession(
            newService,
            ASSET_APIS.cdnSharing,
        );

        const invitationUrl = `${ASSET_APIS.invitations}/auth/${urn}?cdnAcceleration=true`;
        try {
            const composite = await authCompositeAsGuest(
                newSession,
                invitationUrl,
            );
            const manifest = await pullCompositeManifestOnly(
                newSession,
                composite,
            );
            composite.resolvePullWithBranch(manifest);

            const guestData: GuestDcxData = {
                service: newService,
                session: newSession,
                composite,
                manifest,
            };
            setGuestDcxData(guestData);
            return true;
        } catch (err) {
            console.error(err);
        }
        return false;
    }

    async function getCollaborators(urn: string) {
        if (!urn) return;
        const response = await fetch(
            `${ASSET_APIS.invitations}/share/${urn}`,
            defaultRequestOptions("GET"),
        );
        if (response.status != 200) {
            console.error("error getting collaborators", response.statusText);
            return;
        }
        const parsedBody = await response.json();
        if (parsedBody) {
            let collaborators = parsedBody.collaborators;
            if (
                collaborators.find(
                    (collaborator: any) =>
                        collaborator.id == "all" &&
                        collaborator.commentPermissions.includes("read"),
                )
            ) {
                // asset is accessible to anyone with link
                return -1;
            }
            collaborators = collaborators.filter(
                (collaborator: any) =>
                    collaborator.type == "imsUser" ||
                    collaborator.type == "addressBookContact",
            );
            return collaborators.length;
        }
    }

    const cloudDocumentsRequestOptions = {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/vnd.adobecloud.directory+json",
            "X-API-Key": ADOBE_IMS_CONFIG.client_id,
            Authorization: `Bearer ${accessToken}`,
        },
    };

    async function exploreIndexDocument() {
        const data = await cachedFetchJson(
            `${ASSET_APIS.hostAcp}/index`,
            cloudDocumentsRequestOptions,
        );
        const homeDirectory = data.children?.find((child: any) => {
            return child._embedded[
                `${ASSET_APIS.linkRelationBase}/metadata/repository`
            ];
        });

        const primaryUrlArray =
            homeDirectory?.["_embedded"][
                `${ASSET_APIS.linkRelationBase}/metadata/repository`
            ]["_links"][`${ASSET_APIS.linkRelationBase}/primary`];
        const primaryPathObj = primaryUrlArray?.find(
            (el: any) => el.mode === "path",
        );
        return primaryPathObj?.href;
    }

    const [quotaLink, setQuotaLink] = useState<string>("");
    async function exploreHomeDirectory(primaryUrl: string) {
        const data = await cachedFetchJson(
            primaryUrl,
            cloudDocumentsRequestOptions,
        );
        if (!quotaLink)
            setQuotaLink(
                data["_links"][`${ASSET_APIS.linkRelationBase}/quota`]["href"],
            );

        const findCloudDocs = (data: any) => {
            return data.children.find(
                (el: any) => el["repo:name"] === "cloud-content",
            );
        };
        let cloudDocs = findCloudDocs(data);

        // const tempFileName = "hi5_init.txt";
        // let shouldCleanUp = false;
        if (!cloudDocs) {
            deleteCache(primaryUrl, cloudDocumentsRequestOptions);
            // the user has a free account that does not yet have the cloud-content directory created
            // create a temp file to trigger the creation of cloud-content
            const createUrlTemplate =
                data["_links"][`${ASSET_APIS.linkRelationBase}/create`]["href"];
            console.log("createUrlTemplate", createUrlTemplate);
            const createUrl = createUrlTemplate.replace(
                /\{.+\}/,
                `?path=cloud-content&intermediates=true`,
            );

            const createUrlResponse = await fetch(createUrl, {
                method: "POST",
                cache: "default",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "x-api-key": ADOBE_IMS_CONFIG.client_id,
                    "content-type": "application/vnd.adobecloud.directory+json",
                }
            });

            if (createUrlResponse.ok) {
                const docData = await cachedFetchJson(
                    primaryUrl,
                    cloudDocumentsRequestOptions,
                );
                cloudDocs = findCloudDocs(docData);
                // shouldCleanUp = true; // clean up the temp file at the next step
            }
        }

        if (!cloudDocs) {
            console.error("error fetching Cloud Documents link");
            return;
        }
        return {
            cloudDocumentsUrl:
                cloudDocs["_links"][`${ASSET_APIS.linkRelationBase}/primary`][
                    "href"
                ],
        };
    }

    const [storageQuotaData, setStorageQuotaData] = useState<
        StorageQuotaData | undefined
    >();
    async function getStorageQuota() {
        if (!quotaLink) return;
        const response = await fetch(quotaLink, defaultRequestOptions("GET"));
        const parsedBody = await response.json();
        if (!parsedBody) {
            console.error("error getting storage quota");
        }
        const bytesUsed = parsedBody["storage:bytesUsed"];
        const bytesLimit = parsedBody["storage:bytesLimit"];
        const bytesUsedMb = bytesUsed / 1000000;
        const bytesLimitGb = bytesLimit / 1000000000;
        const percentageUsed = parseFloat(
            ((bytesUsedMb / (bytesLimitGb * 1000)) * 100).toFixed(0),
        );
        const data: StorageQuotaData = {
            bytesLimit,
            bytesUsed,
            percentageUsed,
        };
        setStorageQuotaData(data);
    }

    async function getApplicationMetadata(applicationMetadataUrl: string) {
        if (!(accessToken && applicationMetadataUrl)) return;
        const response = await fetch(applicationMetadataUrl, {
            method: "GET",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                Accept: "application/json",
            },
        });
        const parsedBody = await response.json();
        return parsedBody;
    }

    async function updateApplicationMetadata(
        applicationMetadataUrl: string,
        path: string,
        value: string,
    ) {
        if (!(accessToken && applicationMetadataUrl)) return;
        const body = [
            {
                op: "add",
                path,
                value,
            },
        ];
        const response = await fetch(applicationMetadataUrl, {
            method: "PATCH",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "Content-Type": "application/json-patch+json",
                "If-Match": "*",
            },
            body: JSON.stringify(body),
        });
        if (response.status != 204) {
            console.error("error updating application metadata");
        }
    }

    const [cloudDocumentsPrimaryUrl, setCloudDocumentsPrimaryUrl] =
        useState<string>("");
    const [cloudContentAssetId, setCloudContentAssetId] = useState<string>("");
    const [cloudContentRepositoryId, setCloudContentRepositoryId] =
        useState<string>("");
    const [
        cloudContentApplicationMetadataUrl,
        setCloudContentApplicationMetadataUrl,
    ] = useState<string>("");
    async function getCloudDocuments() {
        let cloudDocumentsUrl = cloudDocumentsPrimaryUrl;

        if (!cloudDocumentsUrl) {
            const homePrimaryUrl = await exploreIndexDocument();
            const response = await exploreHomeDirectory(homePrimaryUrl);
            if (response) {
                cloudDocumentsUrl = response.cloudDocumentsUrl;
                setCloudDocumentsPrimaryUrl(cloudDocumentsUrl);
            }
        }

        const cloudDocuments = await cachedFetchJson(
            cloudDocumentsUrl,
            cloudDocumentsRequestOptions,
        );

        if (!cloudDocuments) {
            console.error("error fetching Cloud Documents");
            if (!window.location.href.includes("start-screen"))
                sendLaunchAnalytics(EventType.error, window.location.pathname);
            return;
        }

        if (!window.location.href.includes("start-screen")) {
            sendLaunchAnalytics(EventType.success, window.location.pathname);
        }

        const appMetaDataUrl =
            cloudDocuments["_links"][
                `${ASSET_APIS.linkRelationBase}/metadata/application`
            ]["href"];
        setCloudContentApplicationMetadataUrl(appMetaDataUrl);
        if (!cloudContentAssetId)
            setCloudContentAssetId(cloudDocuments["repo:assetId"]);
        if (!cloudContentRepositoryId)
            setCloudContentRepositoryId(cloudDocuments["repo:repositoryId"]);
        setOnline(true);
        return cloudDocuments;
    }

    const [assetRepositoryId, setAssetRepositoryId] = useState<string>("");
    async function getRepositoryId(
        urn: string,
        isYourAsset?: boolean,
        shouldSet?: boolean,
    ) {
        if (isYourAsset && cloudContentRepositoryId)
            return cloudContentRepositoryId;
        const parsedBody = await cachedFetchJson(
            `${ASSET_APIS.hostAcp}/content/directory/resolve?id=${urn}`,
            defaultRequestOptions("GET"),
        );
        if (!parsedBody) {
            console.error("error getting repositoryId");
        }
        const repositoryId = parsedBody && parsedBody["repo:repositoryId"];
        if (shouldSet) setAssetRepositoryId(repositoryId);
        return repositoryId;
    }

    // cloud documents paging template
    const expandPageUrlTemplate = (urlTemplate: string) => {
        return urlTemplate
            .replace(
                "resource:{resource}",
                `resource:${encodeURIComponent(
                    `${ASSET_APIS.linkRelationBase}/primary`,
                )}`,
            )
            .replace(
                "{?orderBy,start,limit,type,embed}",
                `?orderBy=${encodeURIComponent(
                    "-repo:modifyDate",
                )}&limit=100&type=${encodeURIComponent(
                    "application/vnd.adobe.usdcx+dcx",
                )}`,
            );
    };

    // get a list of metadata for all USDCX files in the user's Cloud Documents
    const [yourAssets, setYourAssets] = useState<AssetMetadata[]>();
    const [yourAssetsHasChange, setYourAssetsHasChange] =
        useState<boolean>(false);
    async function getYourAssets(shouldSet?: boolean) {
        if (!accessToken) return;
        const cloudDocuments = await getCloudDocuments();
        const metadataList: Array<AssetMetadata> = [];
        const getAssetData = (assets: any) => {
            for (const asset of assets) {
                const urn = asset["repo:assetId"];
                const displayName = asset["repo:name"].replace(".usdcx", "");
                const createdBy = asset["repo:createdBy"];
                const modifyDate = asset["repo:modifyDate"];
                const thumbnailUrl =
                    yourAssetsThumbnails && yourAssetsThumbnails[urn];
                const isBlocked = blockedAssets.has(urn);
                const metadata: AssetMetadata = {
                    urn,
                    displayName,
                    createdBy,
                    modifyDate,
                    thumbnailUrl,
                    isBlocked,
                };
                metadataList.push(metadata);
            }
        };

        const pageUrlTemplate =
            cloudDocuments["_links"][`${ASSET_APIS.linkRelationBase}/page`][
                "href"
            ];
        const expandedPageUrl = expandPageUrlTemplate(pageUrlTemplate);
        const response = await fetch(
            expandedPageUrl,
            defaultRequestOptions("GET"),
        );
        const parsedBody = await response.json();
        if (!parsedBody.children) return;
        await getAssetData(parsedBody.children);

        let nextPage = parsedBody["_links"]["next"];
        while (nextPage) {
            const nextPageUrlTemplate = nextPage["href"];
            const expandedNextPageUrl =
                expandPageUrlTemplate(nextPageUrlTemplate);
            const nextResponse = await fetch(
                expandedNextPageUrl,
                defaultRequestOptions("GET"),
            );
            const nextParsedBody = await nextResponse.json();
            if (!nextParsedBody.children) return;
            await getAssetData(nextParsedBody.children);
            nextPage = nextParsedBody["_links"]["next"];
        }
        if (shouldSet) {
            setYourAssets(metadataList);
            setYourAssetsHasChange(true);
        }
        return metadataList;
    }

    function universalSearchRequestOptions(): RequestInit {
        const options: RequestInit = {
            method: "POST",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "content-type": "application/vnd.adobe.search-request+json",
                "x-product": "highfive/1.0",
                "x-product-location": "highfive 1.0",
            },
        };
        return options;
    }

    // get a list of metadata for all USDCX files shared with the user
    const [sharedWithYouAssets, setSharedWithYouAssets] =
        useState<AssetMetadata[]>();
    const [sharedWithYouAssetsHasChange, setSharedWithYouAssetsHasChange] =
        useState<boolean>(false);
    async function getSharedWithYouAssets(shouldSet?: boolean) {
        if (!accessToken) return;

        const options = universalSearchRequestOptions();
        const body = JSON.stringify({
            scope: ["creative_cloud"],
            ownership_types: ["shared_with_me"],
            sort_orderby: "sync_updated_date",
            fetch_fields: {
                includes: ["app_metadata"],
            },
            shared_via_invite: true,
            hints: {
                acp_platform_v2: true,
            },
            "storage:assignee$$type": [],
            op_none_of: [
                {
                    "app_metadata$$shell:visibility": "hiddenSelf",
                },
                {
                    "app_metadata$$project:directoryType": "project",
                },
                {
                    "app_metadata$$project:directoryType": "team",
                },
                {
                    creative_cloud_toplevel_collection_name: ["appdata"],
                },
                {
                    creative_cloud_toplevel_collection_name: "files",
                },
            ],
            q: "*",
            cc_teams_user_storage: true,
            creative_cloud_asset_type: ["dcx_composite"],
            sort_order: "desc",
            limit: 100,
        });
        options.body = body;
        const response = await fetch(
            `${ASSET_APIS.universalSearch}/search`,
            options,
        );

        const parsedBody = await response.json();
        const metadataList: Array<AssetMetadata> = [];
        if (parsedBody) {
            if (
                !(
                    parsedBody["result_sets"] &&
                    parsedBody["result_sets"][0] &&
                    parsedBody["result_sets"][0]["items"]
                )
            )
                return;

            for (const asset of parsedBody["result_sets"][0]["items"]) {
                if (asset["type"] !== "application/vnd.adobe.usdcx+dcx")
                    continue;
                let urn = asset["asset_id"];
                // force region code to be upper case
                const regionId = urn.split(":")[3];
                urn = urn.replace(regionId, regionId.toUpperCase());
                const displayName = asset["repo:name"].replace(".usdcx", "");
                const createdBy = asset["creative_cloud_creator_id"];
                const modifyDate = asset["modify_date"];
                const metadata: AssetMetadata = {
                    urn,
                    displayName,
                    createdBy,
                    modifyDate,
                };
                metadataList.push(metadata);
            }
        }
        if (shouldSet) {
            setSharedWithYouAssets(metadataList);
            setSharedWithYouAssetsHasChange(true);
        }
        return metadataList;
    }

    // get a list of metadata for all of user's discarded USDCX files
    // needed for exposing a 'Deleted files' folder
    async function getDiscardedAssets() {
        if (!accessToken) return;

        const options = universalSearchRequestOptions();
        const body = JSON.stringify({
            scope: ["creative_cloud"],
            asset_id_opacity: true,
            hints: {
                acp_platform_v2: true,
            },
            creative_cloud_archive: true,
            creative_cloud_discarded_directly: true,
            type: [],
            fetch_fields: {
                includes: ["app_metadata"],
            },
            cc_teams_user_storage: true,
            sort_orderby: "modify_date",
            sort_order: "desc",
            limit: 50,
            start_index: 0,
        });
        options.body = body;
        const response = await fetch(
            `${ASSET_APIS.universalSearch}/search`,
            options,
        );

        const parsedBody = await response.json();
        const metadataList: Array<AssetMetadata> = [];
        if (parsedBody) {
            if (
                !(
                    parsedBody["result_sets"] &&
                    parsedBody["result_sets"][0] &&
                    parsedBody["result_sets"][0]["items"]
                )
            )
                return;

            for (const asset of parsedBody["result_sets"][0]["items"]) {
                if (asset["type"] !== "application/vnd.adobe.usdcx+dcx")
                    continue;
                let urn = asset["asset_id"];
                // force region code to be upper case
                const regionId = urn.split(":")[3];
                urn = urn.replace(regionId, regionId.toUpperCase());
                const displayName = asset["repo:name"].replace(".usdcx", "");
                const metadata: AssetMetadata = {
                    urn,
                    displayName,
                };
                metadataList.push(metadata);
            }
        }
        return metadataList;
    }

    const blockedAssets: Set<string> = new Set(); // non-GLB uploads should be blocked until GLB conversion is finished
    async function onAssetUploadCompleted(
        urn: string,
        originalExtension: string,
    ) {
        const cloudDocuments = await getCloudDocuments();
        const pageUrlTemplate =
            cloudDocuments["_links"][`${ASSET_APIS.linkRelationBase}/page`][
                "href"
            ];
        const expandedPageUrl = expandPageUrlTemplate(pageUrlTemplate);
        const response = await fetch(
            expandedPageUrl,
            defaultRequestOptions("GET"),
        );
        const parsedBody = await response.json();
        if (!parsedBody.children) return;
        const newAsset = parsedBody.children.find(
            (asset: any) => asset["repo:assetId"] === urn,
        );
        const displayName = newAsset["repo:name"].replace(".usdcx", "");
        const createdBy = newAsset["repo:createdBy"];
        const modifyDate = newAsset["repo:modifyDate"];
        const metadata: AssetMetadata = {
            urn,
            displayName,
            createdBy,
            modifyDate,
            isBlocked: originalExtension !== "glb", // non-GLB uploads should be blocked until GLB conversion is finished
        };

        const updaterFn = (currentYourAssets: AssetMetadata[] | undefined) => {
            if (!currentYourAssets) return [metadata];
            const yourAssetsCopy = currentYourAssets.slice();
            yourAssetsCopy.unshift(metadata);
            return yourAssetsCopy;
        };
        setYourAssets(updaterFn);
        setYourAssetsHasChange(true);
        blockedAssets.add(urn);
    }

    async function onGlbConversionCompleted(urn: string) {
        if (!yourAssets) return;

        const updaterFn = (currentYourAssets: AssetMetadata[] | undefined) => {
            if (!currentYourAssets) return [];
            const yourAssetsCopy = currentYourAssets.slice();
            const newAsset = yourAssetsCopy.find((asset) => asset.urn === urn);
            if (newAsset) {
                newAsset.isBlocked = false;
                blockedAssets.delete(newAsset.urn);
            }
            return yourAssetsCopy;
        };
        setYourAssets(updaterFn);
    }

    async function onThumbnailUploadCompleted(urn: string) {
        if (!yourAssets) return;
        const thumbnail = await getThumbnail(urn, true);
        const updaterFn = (currentYourAssets: AssetMetadata[] | undefined) => {
            if (!currentYourAssets) return [];
            const yourAssetsCopy = currentYourAssets.slice();
            const newAsset = yourAssetsCopy.find((asset) => asset.urn === urn);
            if (newAsset) newAsset.thumbnailUrl = thumbnail;
            return yourAssetsCopy;
        };
        setYourAssets(updaterFn);
    }

    const updateYourAssetsCollaboratorsAndThumbnails = useCallback(async () => {
        if (!(yourAssets && yourAssetsHasChange)) return;

        const yourAssetsCopy = yourAssets.slice();
        await Promise.all(
            yourAssetsCopy.map(async (data) => {
                const thumbnail = await getThumbnail(data.urn, true);
                data.thumbnailUrl = thumbnail;
                const collaborators = await getCollaborators(data.urn);
                data.collaborators = collaborators ? collaborators : 1;
            }),
        );

        const thumbnails: Record<string, string | undefined> = {};
        for (const data of yourAssetsCopy) {
            thumbnails[data.urn] = data.thumbnailUrl;
        }

        const updaterFn = (currentYourAssets: AssetMetadata[] | undefined) => {
            if (!currentYourAssets) return yourAssetsCopy;
            const diffUrns = currentYourAssets
                .map((asset) => asset.urn)
                .filter(
                    (urn) =>
                        !yourAssetsCopy.map((asset) => asset.urn).includes(urn),
                );
            if (diffUrns.length === 0) return yourAssetsCopy;
            const diffAssets = currentYourAssets.filter((asset) =>
                diffUrns.includes(asset.urn),
            );
            return diffAssets.concat(yourAssetsCopy);
        };
        setYourAssets(updaterFn);
        setYourAssetsThumbnails(thumbnails);
        setYourAssetsHasChange(false);
    }, [yourAssetsHasChange]);

    useEffect(() => {
        updateYourAssetsCollaboratorsAndThumbnails().catch(console.error);
    }, [updateYourAssetsCollaboratorsAndThumbnails]);

    const updateSharedWithYouAssetsCollaboratorsAndThumbnails =
        useCallback(async () => {
            if (!(sharedWithYouAssets && sharedWithYouAssetsHasChange)) return;
            const sharedWithYouAssetsCopy = sharedWithYouAssets.slice();

            await Promise.all(
                sharedWithYouAssetsCopy.map(async (data) => {
                    const thumbnail = await getThumbnail(data.urn, false);
                    data.thumbnailUrl = thumbnail;
                    const collaborators = await getCollaborators(data.urn);
                    data.collaborators = collaborators ? collaborators : 1;
                }),
            );

            const thumbnails: Record<string, string | undefined> = {};
            for (const data of sharedWithYouAssetsCopy) {
                thumbnails[data.urn] = data.thumbnailUrl;
            }

            setSharedWithYouAssets(sharedWithYouAssetsCopy);
            setSharedWithYouAssetsThumbnails(thumbnails);
            setSharedWithYouAssetsHasChange(false);
        }, [sharedWithYouAssetsHasChange]);

    useEffect(() => {
        updateSharedWithYouAssetsCollaboratorsAndThumbnails().catch(
            console.error,
        );
    }, [updateSharedWithYouAssetsCollaboratorsAndThumbnails]);

    const [assetMetadata, setAssetMetadata] = useState<AssetMetadata>();
    // get the repository metadata for a particular asset
    async function getMetadata(urn: string, shouldSet?: boolean) {
        let metadataResponse;
        if (guestDcxData) {
            if (!(guestDcxData.session && guestDcxData.composite)) return;
            const { result } = await guestDcxData.session.getRepoMetadata(
                guestDcxData.composite,
            );
            metadataResponse = result;
        } else {
            if (!(accessToken && urn)) return;
            const repositoryId = await getRepositoryId(urn, shouldSet);
            if (!repositoryId) return;

            const response = await fetch(
                `${ASSET_APIS.hostAcp}/content/directory/resolve?repositoryId=${repositoryId}&id=${urn}&resource=${ASSET_APIS.linkRelationBase}/metadata/repository`,
                defaultRequestOptions("GET"),
            );

            if (response.status >= 400) {
                console.error("error getting repository metadata");
                return;
            }
            metadataResponse = await response.json();
        }

        const namePropertyName = guestDcxData ? "name" : "repo:name";
        const createdByPropertyName = guestDcxData
            ? "createdBy"
            : "repo:createdBy";
        const modifyDatePropertyName = guestDcxData
            ? "modifyDate"
            : "repo:modifyDate";
        const linksPropertyName = guestDcxData ? "links" : "_links";
        if (metadataResponse) {
            const displayName = metadataResponse[namePropertyName].replace(
                ".usdcx",
                "",
            );
            const createdBy = metadataResponse[createdByPropertyName];
            const modifyDate = metadataResponse[modifyDatePropertyName];
            const manifestUrl =
                metadataResponse[linksPropertyName][
                    `${ASSET_APIS.linkRelationBase}/manifest`
                ][0]["href"];
            const applicationMetadataUrl =
                metadataResponse[linksPropertyName][
                    `${ASSET_APIS.linkRelationBase}/metadata/application`
                ][0]["href"];
            const metadata: AssetMetadata = {
                urn,
                displayName,
                createdBy,
                modifyDate,
                manifestUrl,
                applicationMetadataUrl,
            };
            if (shouldSet) setAssetMetadata(metadata);
            return metadata;
        } else {
            console.error("error getting asset meta data");
            return;
        }
    }

    /**
     * Gets the relevant data for the GLB model component
     * If the source-document is not a GLB, look for a GLB file in renditions/
     * @param urn: string - the urn of the usdcx composite
     * @returns ComponentData
     *
     */
    const [assetComponentData, setAssetComponentData] = useState<
        ComponentData | undefined
    >();
    const [brokenAssetUrn, setBrokenAssetUrn] = useState<string>("");
    async function getComponentData(
        urn: string,
        shouldSet?: boolean,
        isPoll?: boolean,
    ) {
        let manifest;
        let manifestUrl;

        if (guestDcxData) {
            manifest = guestDcxData.manifest && guestDcxData.manifest._data;
        } else {
            if (!(accessToken && urn)) return;
            if (assetMetadata && assetMetadata.urn === urn) {
                manifestUrl = assetMetadata.manifestUrl;
            } else {
                const metadata = await getMetadata(urn);
                manifestUrl = metadata && metadata.manifestUrl;
            }
            if (!manifestUrl) {
                console.error("error getting manifest for: ", urn);
                return;
            }
            const response = await fetch(
                manifestUrl,
                defaultRequestOptions("GET"),
            );
            manifest = await response.json();
        }

        if (manifest && manifest.status == 404) {
            if (shouldSet) {
                // called from review
                setBrokenAssetUrn(urn);
            } else if (!isPoll) {
                // called from CDO when NOT polling for asset updates
                // delete "Partially Created Asset", upload was likely interrupted and asset is unusuable
                console.log("deleting partially created asset");
                deleteAsset(urn);
            }
        }

        if (manifest && manifest.components) {
            const sourceDocumentPath = manifest["usdcx#source-document"];
            let modelRenditions = [];
            if (manifest.children) {
                const renditions = manifest.children.find(
                    (component: any) => component.path === "renditions",
                );
                if (renditions) {
                    modelRenditions = renditions.components.filter(
                        (component: any) => {
                            return component.type.startsWith("model/");
                        },
                    );
                }
            }
            const convertedGlb = manifest.components.find(
                (comp: { path: string }) =>
                    comp.path === "renditions/convertedGlbModel.glb",
            );
            if (convertedGlb) modelRenditions.push(convertedGlb);

            const sourceDocument = manifest.components.find(
                (component: any) => {
                    return component.path === sourceDocumentPath;
                },
            );

            let glbRendition;
            let originalFormat = "glb";
            if (sourceDocumentPath && sourceDocumentPath.endsWith(".glb")) {
                glbRendition = sourceDocument;
            } else {
                originalFormat = sourceDocumentPath.match(/[^\.]+$/)[0];
                glbRendition = modelRenditions.find((component: any) => {
                    return component.path.endsWith(".glb");
                });
            }

            if (!glbRendition) {
                console.error("glb rendition not found");
                return;
            }
            const thumbnail = manifest.components.find((component: any) => {
                return (
                    component.name === "thumbnail" &&
                    component.path.startsWith("renditions/")
                );
            });

            const usdzRendition = manifest.components.find((component: any) => {
                return component.path.endsWith(".usdz");
            });

            const componentData: ComponentData = {
                componentId: glbRendition.id,
                componentVersion: glbRendition.version,
                thumbnailId: thumbnail && thumbnail.id,
                thumbnailVersion: thumbnail && thumbnail.version,
                usdzComponentId: usdzRendition && usdzRendition.id,
                usdzComponentVersion: usdzRendition && usdzRendition.version,
                originalFormat,
            };
            if (shouldSet) setAssetComponentData(componentData);
            return componentData;
        } else {
            console.error("error getting component data for: ", urn);
            return;
        }
    }

    async function getThumbnail(urn: string, isYourAsset: boolean) {
        if (!urn) return;
        const missingThumbnailString = "notFound";
        const thumbnails = isYourAsset
            ? yourAssetsThumbnails
            : sharedWithYouAssetsThumbnails;
        const cachedThumbnailUrl = thumbnails && thumbnails[urn];
        if (cachedThumbnailUrl && cachedThumbnailUrl !== missingThumbnailString)
            return cachedThumbnailUrl;

        if (!(accessToken && service)) return;
        const componentData = await getComponentData(urn);
        if (!componentData) return;

        const { thumbnailId } = componentData;
        if (!thumbnailId) {
            return missingThumbnailString;
        }

        const repositoryId = await getRepositoryId(urn, isYourAsset);
        if (!repositoryId) return;

        const renditionOpts = {
            size: 256,
            component_id: thumbnailId,
        };
        if (!session) return;
        const { result } = await session.getRendition(
            {
                repositoryId,
                assetId: urn,
            },
            renditionOpts,
            "blob",
        );

        if (!result) {
            console.error("error getting thumbnail");
            return;
        }
        let url = "";
        try {
            url = URL.createObjectURL(result as unknown as Blob);
        } catch (e) {
            console.error(e);
        }
        return url;
    }

    const [assetDownloadUrl, setAssetDownloadUrl] = useState<string>("");
    async function getDownloadUrl(urn: string, format?: string) {
        const cacheKey = `${urn}${format || ""}`;
        const cachedUrl = urlCache.get(cacheKey);
        if (cachedUrl) {
            setAssetDownloadUrl(cachedUrl);
            return cachedUrl;
        }
        if (!guestDcxData && !(accessToken && urn && service)) return;
        let componentData;
        console.log("getDownloadUrl:", {
            assetComponentData,
            assetMetadata
        })
        if (assetComponentData && assetMetadata && assetMetadata.urn === urn) {
            componentData = assetComponentData;
        } else {
            componentData = await getComponentData(urn);
        }
        if (!componentData) return;
        const isUsdz = format === "usdz";
        const componentId = isUsdz
            ? componentData.usdzComponentId
            : componentData.componentId;
        const componentVersion = isUsdz
            ? componentData.usdzComponentVersion
            : componentData.componentVersion;

        if (!(componentId && componentVersion)) return;

        let repositoryId;
        let composite;
        let currentService;
        if (guestDcxData) {
            composite = guestDcxData.composite;
            currentService = guestDcxData.service;
        } else {
            if (
                assetRepositoryId &&
                assetMetadata &&
                assetMetadata.urn === urn
            ) {
                repositoryId = assetRepositoryId;
            } else {
                repositoryId = await getRepositoryId(urn);
            }
            composite = {
                repositoryId,
                assetId: urn,
            };
            currentService = service;
        }

        if (!currentService) return;
        const { result } = await getPresignedUrl(currentService, composite, {
            reltype: LinkRelation.COMPONENT,
            revision: componentVersion,
            component_id: componentId,
        });
        if (!result) {
            console.error("error getting download url");
            return;
        }
        urlCache.set(cacheKey, result);
        setAssetDownloadUrl(result);
        return result;
    }

    function hasDuplicateName(name: string) {
        let isDuplicateName = false;
        if (yourAssets) {
            isDuplicateName = yourAssets.some(
                (asset) => asset.displayName === name,
            );
        }
        return isDuplicateName;
    }

    // Check if a file with the same name already exists
    function handleDuplicateNames(fileName: string) {
        let isDuplicateName = true;
        let duplicateCnt = 0;
        let newName = fileName;
        if (yourAssets) {
            do {
                isDuplicateName = hasDuplicateName(newName);
                if (isDuplicateName == true) {
                    duplicateCnt++;
                    newName = fileName.concat(`_${duplicateCnt}`);
                }
            } while (isDuplicateName);
        }
        if (duplicateCnt > 0) {
            fileName = newName;
        }
        return fileName;
    }

    async function getOpsUrl() {
        if (!accessToken) return;

        const response = await fetch(
            `${ASSET_APIS.hostAcp}`,
            defaultRequestOptions("GET"),
        );

        const parsedBody = await response.json();

        const opsUrl =
            parsedBody["_links"][`${ASSET_APIS.linkRelationBase}/ops`]["href"];

        return opsUrl;
    }

    function opsRequestOptions(
        op: string,
        urn: string,
        repositoryId: string,
    ): RequestInit {
        const options: RequestInit = {
            method: "POST",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "content-type": "application/vnd.adobe.asset-operation+json",
            },
            body: JSON.stringify({
                op,
                target: {
                    "repo:assetId": `${urn}`,
                    "repo:repositoryId": `${repositoryId}`,
                },
            }),
        };
        return options;
    }

    // Renames asset
    async function renameAsset(
        urn: string,
        sourceName: string,
        targetName: string,
    ) {
        if (!accessToken) return;

        // check if targetName already exists
        const isDuplicateName = hasDuplicateName(targetName);
        if (isDuplicateName) {
            return false;
        }

        const indexResponse = await fetch(
            `${ASSET_APIS.hostAcp}/content/directory/resolve?id=${urn}`,
            defaultRequestOptions("GET"),
        );
        const indexData = await indexResponse.json();
        if (!indexData) {
            console.error("error renaming asset");
            return false;
        }

        const sourcePath = indexData["repo:path"];
        const targetPath = sourcePath.replace(sourceName, targetName);
        const repositoryId = indexData["repo:repositoryId"];

        const opsUrl = await getOpsUrl();
        const response = await fetch(opsUrl, {
            method: "POST",
            cache: "default",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "x-api-key": ADOBE_IMS_CONFIG.client_id,
                "content-type": "application/vnd.adobe.asset-operation+json",
            },
            body: JSON.stringify({
                op: "move",
                source: {
                    "repo:path": `${sourcePath}`,
                    "repo:repositoryId": `${repositoryId}`,
                },
                target: {
                    "repo:path": `${targetPath}`,
                    "repo:repositoryId": `${repositoryId}`,
                },
            }),
        });

        const updaterFn = (currentYourAssets: AssetMetadata[] | undefined) => {
            if (!currentYourAssets) return [];
            const yourAssetsCopy = currentYourAssets.slice();
            const renamedAsset = yourAssetsCopy.findIndex(
                (asset) => asset.urn === urn,
            );
            yourAssetsCopy[renamedAsset].displayName = targetName;
            return yourAssetsCopy;
        };

        if (response.ok) {
            setYourAssets(updaterFn);
            setYourAssetsHasChange(true);
        }
        return response.ok;
    }

    async function discardAsset(urn: string) {
        if (!accessToken) return;

        const repositoryId = await getRepositoryId(urn);
        const opsUrl = await getOpsUrl();
        const options = opsRequestOptions("discard", urn, repositoryId);
        const response = await fetch(opsUrl, options);

        if (response.ok) {
            const updaterFn = (
                currentYourAssets: AssetMetadata[] | undefined,
            ) => {
                if (!currentYourAssets) return [];
                const yourAssetsCopy = currentYourAssets.filter((asset) => {
                    return asset.urn !== urn;
                });
                return yourAssetsCopy;
            };
            setYourAssets(updaterFn);
            setYourAssetsHasChange(true);
        }
        return response.ok;
    }

    // differs from discardAsset in that 'delete' is not undoable
    async function deleteAsset(urn: string, shouldSet?: boolean) {
        if (!accessToken) return;

        const repositoryId = await getRepositoryId(urn);
        const opsUrl = await getOpsUrl();
        const options = opsRequestOptions("delete", urn, repositoryId);
        const response = await fetch(opsUrl, options);

        if (response.ok && shouldSet) {
            const updaterFn = (
                currentYourAssets: AssetMetadata[] | undefined,
            ) => {
                if (!currentYourAssets) return [];
                const yourAssetsCopy = currentYourAssets.filter((asset) => {
                    return asset.urn !== urn;
                });
                return yourAssetsCopy;
            };
            setYourAssets(updaterFn);
            setYourAssetsHasChange(true);
        }
        return response.ok;
    }

    async function restoreAsset(urn: string) {
        if (!accessToken) return;

        const repositoryId = await getRepositoryId(urn);
        const opsUrl = await getOpsUrl();
        const options = opsRequestOptions("restore", urn, repositoryId);
        const response = await fetch(opsUrl, options);

        const restoredAsset = await getMetadata(urn);

        if (response.ok) {
            const updaterFn = (
                currentYourAssets: AssetMetadata[] | undefined,
            ) => {
                if (!restoredAsset) return [];
                if (!currentYourAssets) return [restoredAsset];
                const yourAssetsCopy = currentYourAssets.slice();
                yourAssetsCopy.unshift(restoredAsset);
                return yourAssetsCopy;
            };
            setYourAssets(updaterFn);
            setYourAssetsHasChange(true);
        }

        return response.ok;
    }

    const [cachedBlobs, setCachedBlobs] =
        useState<Record<string, Blob | undefined>>();
    const [cachedBlobUrls, setCachedBlobUrls] =
        useState<Record<string, string | undefined>>();
    async function getAssetAsBlob(urn: string, assetDownloadUrl: string) {
        const cachedBlob = cachedBlobs && cachedBlobs[urn];
        if (cachedBlob) return cachedBlob;

        const response = await fetch(assetDownloadUrl);
        if (response.status != 200) {
            console.error("error downloading asset", response.statusText);
            return;
        }
        const blob = await response.blob();
        const cachedBlobsCopy = Object.assign({}, cachedBlobs);
        cachedBlobsCopy[urn] = blob;
        setCachedBlobs(cachedBlobsCopy);

        const url = URL.createObjectURL(blob);
        const cachedBlobUrlsCopy = Object.assign({}, cachedBlobUrls);
        cachedBlobUrlsCopy[urn] = url;
        setCachedBlobUrls(cachedBlobUrlsCopy);
        return blob;
    }

    function getCachedBlobUrl(urn: string) {
        return cachedBlobUrls && cachedBlobUrls[urn];
    }

    async function setEnvMetadata(envMeta: ReviewerEnvMeta, assetId?: string) {
        if (!session || !(assetUrn || assetId) || !cloudContentRepositoryId) {
            throw new Error(
                "session, cloudContentRepositoryId, and assetUrn/assetId required",
            );
        }
        const urn = assetUrn || assetId;
        const composite = newDCXComposite(urn, cloudContentRepositoryId);
        composite.resolvePullWithBranch(
            await pullCompositeManifestOnly(session, composite),
        );

        let meta = composite.current
            .getChildrenOf(composite.current.rootNode)
            .find((child) => child.name === "reviewer-meta");
        if (!meta) {
            meta = composite.current.addChild("reviewer-meta");
        }

        for (const key in envMeta) {
            meta.setValue(key, envMeta[key as ReviewerEnvMetaKeys]);
        }

        meta.setValue("metaFormatVersion", REVIEWER_EVN_META_VERSION);

        try {
            await pushComposite(session, composite, false);
        } catch (e) {
            console.warn("Failed to push composite env meta. No updates?", e);
        }
    }

    async function getEnvMetadata(assetId?: string, tokensToUrls = false) {
        if (!session || !(assetUrn || assetId) || !cloudContentRepositoryId) {
            console.error("GetEnvMeta error", {
                hasSession: !!session,
                assetUrn,
                assetId,
                cloudContentRepositoryId,
            });
            throw new Error(
                "session, cloudContentRepositoryId, and assetUrn/assetId required",
            );
        }
        const urn = assetUrn || assetId;

        const composite = newDCXComposite(urn, cloudContentRepositoryId);
        composite.resolvePullWithBranch(
            await pullCompositeManifestOnly(session, composite),
        );

        const meta = composite.current
            .getChildrenOf(composite.current.rootNode)
            .find((child) => child.name === "reviewer-meta");

        if (!meta) {
            return;
        }

        const envMeta = reviewerMetaFields.reduce(
            (acc, val: ReviewerEnvMetaKeys) => {
                acc[val] = meta.getValue(val);
                if (tokensToUrls) {
                    if (val === "environment") {
                        acc[val] = getEnvUrl(acc[val] as Environment);
                    }
                    if (val === "pedestal") {
                        acc[val] = getPedestalUrl(acc[val] as Pedestal);
                    }
                }
                return acc;
            },
            {} as ReviewerEnvMeta,
        );
        return envMeta;
    }

    return (
        <AssetContext.Provider
            value={{
                online,
                service,
                session,
                assetUrn,
                setAssetUrn,
                checkUserAccess,
                cloudContentAssetId,
                cloudContentRepositoryId,
                cloudContentApplicationMetadataUrl,
                storageQuotaData,
                getStorageQuota,
                assetMetadata,
                assetComponentData,
                yourAssets,
                yourAssetsHasChange,
                brokenAssetUrn,
                setYourAssets,
                setYourAssetsHasChange,
                setSharedWithYouAssets,
                setSharedWithYouAssetsHasChange,
                sharedWithYouAssets,
                assetDownloadUrl,
                userAccessResponseStatus,
                guestAccessToken,
                getApplicationMetadata,
                updateApplicationMetadata,
                getMetadata,
                getComponentData,
                getThumbnail,
                getDownloadUrl,
                getAssetAsBlob,
                getCachedBlobUrl,
                getYourAssets,
                getSharedWithYouAssets,
                getDiscardedAssets,
                requestAccess,
                onAssetUploadCompleted,
                onGlbConversionCompleted,
                onThumbnailUploadCompleted,
                handleDuplicateNames,
                renameAsset,
                deleteAsset,
                discardAsset,
                restoreAsset,
                setEnvMetadata,
                getEnvMetadata,
            }}>
            {children}
        </AssetContext.Provider>
    );
};
