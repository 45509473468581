/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Studio, StudioTool } from "@components/studio";
import { SceneManager } from "@components/studio/src/scene/SceneManager";
import { useTranslation } from "react-i18next";

import { viewerConfig } from "../viewerConfig";

import type { AdobeViewer } from "@3di/adobe-3d-viewer";
import type { Scene } from "@babylonjs/core/scene";
import type { StudioStrings } from "@components/studio";
import type { RenderSettings } from "@shared/types";

interface StudioViewProps {
    modelUrl?: string;
    editorTools?: StudioTool[];
    setSceneInstance?: (sceneInstance: Scene) => void;
    setViewerInstance?: (viewerInstance: AdobeViewer) => void;
    setSceneManager?: (sceneManager: SceneManager) => void;
}

export const renderSettings: RenderSettings = {
    groundPlaneEnabled: false,
    backgroundColor: [1,1,1,1],
    // useIblShadows: true,
};

export function StudioView({
    modelUrl,
    editorTools = [StudioTool.none],
    setSceneInstance,
    setViewerInstance,
    setSceneManager,
}: StudioViewProps) {
    const { t } = useTranslation("web");

    const editorStrings: StudioStrings = {
        cameraOrbit: t("studio.cameraTools.orbit"),
        cameraPan: t("studio.cameraTools.pan"),
        cameraDolly: t("studio.cameraTools.dolly"),
        frameButton: t("studio.cameraTools.frameButton"),

        addHotSpot: "",
        moveHotSpot: "",
        deleteHotSpot: "",
        close: "",
        placeHolderTitle: "",
        placeHolderText: "",
        titleLabel: "",
        textLabel: "",
        toastHotSpotAddMessage: "",
        toastHotSpotMoveMessage: "",
        toastHotSpotAction: "",
    };

    function updateManager(sceneManager: SceneManager) {
        const viewer = sceneManager.viewer;
        if (viewer) {
            viewer.onAdobeViewerRenderCompleteObservable.addOnce(() => {
                if (sceneManager.scene.environmentIntensity)
                    sceneManager.scene.environmentIntensity = 0.5;
                if (sceneManager.scene.shadowsEnabled)
                    sceneManager.scene.shadowsEnabled = false;
                sceneManager.frame();
            });
        }
        setSceneManager?.(sceneManager);
        setViewerInstance?.(sceneManager.viewer);
        setSceneInstance?.(sceneManager.scene);
    }

    return (
        <Studio
            initialHotSpots={undefined}
            initialCameraOverride={undefined}
            enableLimitedZoom={false}
            modelUrl={modelUrl}
            iblUrl={viewerConfig.iblUrl}
            cameraName={undefined}
            onHotSpotsUpdate={undefined}
            onCameraOverrideUpdate={undefined}
            tools={editorTools}
            strings={editorStrings}
            aspectRatio={0}
            wideFraming={true}
            renderSettings={renderSettings}
            setSceneManager={updateManager}
            viewerConfig={viewerConfig}
        />
    );
}
