/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Text,
    Flex,
    Avatar,
    View,
    Badge,
    ListBox,
    Item,
    DialogTrigger,
    ActionButton,
    Button,
    Dialog,
    Content,
    Tooltip,
    TooltipTrigger,
    MenuTrigger,
    Menu,
} from "@adobe/react-spectrum";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import Crosshairs from "@spectrum-icons/workflow/Crosshairs";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useNetworkContext } from "@src/contexts/NetworkContext";
import { AFK_COLOR } from "@src/networking/AvatarsManager";

interface PresenceBubblesProps {
    muted: boolean;
    device: string;
    users: Set<string>;
}

const maxUsersDisplayed = 4;

export function PresenceBubbles({
    muted,
    device,
    users,
}: PresenceBubblesProps) {
    const { t } = useTranslation("common");
    const { networkManager, voiceManager } = useNetworkContext();

    const [colorsMap, setColorsMap] = useState<Record<number, string>>({});
    const [talkingFlagsMap, setTalkingFlagsMap] = useState<
        Record<number, boolean>
    >({}); // mapping of actors to their talking status
    const [idleFlagsMap, setIdleFlagsMap] = useState<Record<number, boolean>>(
        {},
    ); // mapping of actors to their talking status

    const [showPopover, setShowPopover] = useState(false); // show popover when avatar is clicked

    useEffect(() => {
        const handleUserColorAssigned = (event: any) => {
            const { actorNr, color } = event.detail;
            if (color == null) {
                setColorsMap((prev) => {
                    delete prev[actorNr];
                    return prev;
                });
            } else {
                setColorsMap((prev) => ({
                    ...prev,
                    [actorNr]: color,
                }));
            }
        };

        window.addEventListener("onUserColorAssigned", handleUserColorAssigned);

        return () => {
            window.removeEventListener(
                "onUserColorAssigned",
                handleUserColorAssigned,
            );
        };
    }, []);

    useEffect(() => {
        const handleIsTalking = (event: any) => {
            const { actorNr, isTalking } = event.detail;
            setTalkingFlagsMap((prev) => ({
                ...prev,
                [actorNr]: isTalking,
            }));
        };

        window.addEventListener("isTalking", handleIsTalking);

        return () => {
            window.removeEventListener("isTalking", handleIsTalking);
        };
    }, []);

    useEffect(() => {
        const handleIsIdle = (event: any) => {
            const { actorNr, isIdle } = event.detail;
            setIdleFlagsMap((prev) => ({
                ...prev,
                [actorNr]: isIdle,
            }));
        };

        window.addEventListener("isIdle", handleIsIdle);

        return () => {
            window.removeEventListener("isIdle", handleIsIdle);
        };
    }, []);

    // run local speech detection when microphone is on
    useEffect(() => {
        const stopFlag = { current: false }; // stops loop if mic is true
        if (!muted) {
            detectSpeaking(stopFlag);
        } else {
            setTalkingFlagsMap((prev) => ({
                ...prev,
                [networkManager?.myActor().actorNr ?? 0]: false,
            }));
            networkManager?.setIsTalkingFlag(false);
        }

        return () => {
            stopFlag.current = true;
        };
    }, [muted]);

    // connects and disconnects to voice room <-- not used (may be used in future)
    // const handleConnectAudio = () => {
    //     if (voiceManager?.isJoinedLobby()) {
    //         voiceManager.joinVoiceRoom(voiceRoom);
    //     } else if (voiceManager?.isJoinedRoom()) {
    //         voiceManager.leaveRoom();
    //     }
    // };

    useEffect(() => {
        // <-- new way to toggle mic
        voiceManager?.toggleMic(!muted, device);

        networkManager?.setIsMutedFlag(muted);
    }, [muted]);

    const [clickedAvatar, setClickedAvatar] = useState<string | undefined>(
        undefined,
    );
    const handleClickedAvatar = (name: string) => {
        setShowPopover(true);
        setClickedAvatar(name);
    };

    const handleCloseClickedAvatar = () => {
        setShowPopover(false);
    };

    function handleMenuAction(key: any) {
        //todo handle action from menu click (needs action+Name?)
        console.log("Menu action: " + key);

        handleCloseClickedAvatar();
    }

    useEffect(() => {
        const actors = networkManager?.myRoomActorsArray();
        if (users?.size === 0) return;
        if (!actors) return;
    }, [showPopover, users]);

    // detects local audio streams from microphone
    async function detectSpeaking(stopFlag: { current: boolean }) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            const audioContext = new AudioContext();
            const mediaStreamSource =
                audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            mediaStreamSource.connect(analyser);

            const dataArray = new Uint8Array(analyser.fftSize);
            const speakingThreshold = 0.02;
            const smoothingTime = 200;
            let lastStateChange = Date.now();
            let isCurrentlySpeaking = false;

            const detect = () => {
                if (stopFlag.current) {
                    stream.getTracks().forEach((track) => track.stop());
                    audioContext.close();
                    return;
                }

                analyser.getByteTimeDomainData(dataArray);

                let isSpeakingNow = false;
                for (let i = 0; i < dataArray.length; i++) {
                    const amplitude = dataArray[i] / 128 - 1.0;
                    if (Math.abs(amplitude) > speakingThreshold) {
                        isSpeakingNow = true;
                        break;
                    }
                }

                const now = Date.now();

                if (isSpeakingNow !== isCurrentlySpeaking) {
                    if (
                        isSpeakingNow ||
                        now - lastStateChange > smoothingTime
                    ) {
                        isCurrentlySpeaking = isSpeakingNow;
                        setTalkingFlagsMap((prev) => ({
                            ...prev,
                            [networkManager?.myActor().actorNr ?? 0]:
                                isCurrentlySpeaking,
                        }));
                        networkManager?.setIsTalkingFlag(isCurrentlySpeaking);
                        lastStateChange = now;
                    }
                } else {
                    lastStateChange = now;
                }

                requestAnimationFrame(detect);
            };

            detect();
        } catch (error) {
            console.error("Error accessing the microphone: ", error);
        }
    }

    function renderLimitedAvatarView(actors: Photon.LoadBalancing.Actor[]) {
        let actorCount = 0; // using actor count becuase actorNr is always increment every time a new actor joins
        const overlapOffset = "-8px";
        return (
            <View>
                <Flex direction="row">
                    {actors.map((actor) => {
                        actorCount++;
                        const { avatarUrl, platform } =
                            actor.getCustomProperties();
                        const zIndex = actors.length - actorCount;
                        return (
                            <View key={actorCount}>
                                {showPopover ? (
                                    <MenuTrigger
                                        isOpen={
                                            showPopover &&
                                            actor.name === clickedAvatar
                                        }>
                                        <ActionButton
                                            isQuiet
                                            UNSAFE_style={{
                                                // position: "absolute",
                                                // left: leftOffset,
                                                marginRight: overlapOffset,
                                                zIndex: zIndex,
                                                borderBlockColor: "",
                                            }}>
                                            <Avatar
                                                key={actor.actorNr}
                                                size="avatar-size-400"
                                                alt={t(
                                                    "accessibility.account.profilePicture",
                                                )}
                                                src={avatarUrl}
                                                UNSAFE_style={{
                                                    border: "2px solid #303030",
                                                    boxShadow: `0 0 0 2px ${talkingFlagsMap[actor.actorNr] ? `white` : idleFlagsMap[actor.actorNr] ? AFK_COLOR : colorsMap[actor.actorNr]}`,
                                                }}
                                            />
                                        </ActionButton>
                                        <Menu
                                        // TODO: disabling for now since teleport is not implemented yet
                                        // onAction={(key) =>
                                        //     handleMenuAction(key)
                                        // }
                                        >
                                            <Item key="teleport">
                                                <Crosshairs />
                                                <Text>
                                                    {t("actions.teleport")}
                                                </Text>
                                            </Item>
                                        </Menu>
                                    </MenuTrigger>
                                ) : (
                                    <TooltipTrigger
                                        placement="bottom"
                                        delay={0}>
                                        <ActionButton
                                            isQuiet
                                            // TODO: disabling for now since teleport is not implemented yet
                                            // onPress={() =>
                                            //     handleClickedAvatar(actor.name)
                                            // }
                                            UNSAFE_style={{
                                                // position: "absolute",
                                                // left: leftOffset,
                                                zIndex: 1,
                                                marginRight: overlapOffset,
                                            }}>
                                            <Avatar
                                                key={actor.actorNr}
                                                size="avatar-size-400"
                                                alt={t(
                                                    "accessibility.account.profilePicture",
                                                )}
                                                src={avatarUrl}
                                                UNSAFE_style={{
                                                    border: "2px solid #303030",
                                                    boxShadow: `0 0 0 2px ${talkingFlagsMap[actor.actorNr] ? `white` : idleFlagsMap[actor.actorNr] ? AFK_COLOR : colorsMap[actor.actorNr]}`,
                                                }}
                                            />
                                        </ActionButton>
                                        <Tooltip>
                                            <Text>{actor.name}</Text>
                                            <Badge
                                                variant="indigo"
                                                UNSAFE_style={{
                                                    left: 3,
                                                }}>
                                                {platform === "VR"
                                                    ? "VR"
                                                    : "Web"}
                                            </Badge>
                                        </Tooltip>
                                    </TooltipTrigger>
                                )}
                            </View>
                        );
                    })}
                </Flex>
            </View>
        );
    }

    function renderAvatarDropDownView() {
        const actors = networkManager?.myRoomActorsArray() ?? [];
        const extraUsers = actors.length - maxUsersDisplayed;
        return (
            <Flex direction="row">
                <View>
                    {renderLimitedAvatarView(
                        actors.slice(0, maxUsersDisplayed),
                    )}
                </View>
                <View>
                    <DialogTrigger type="popover" placement="bottom">
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{
                                marginLeft: "12px",
                            }}>
                            <ChevronDown />
                        </ActionButton>
                        <Dialog>
                            <Content>
                                <ListBox>
                                    {actors.map((actor) => {
                                        const { avatarUrl, platform } =
                                            actor.getCustomProperties();
                                        const width =
                                            platform === "VR" ? 35 : 40;
                                        return (
                                            <Item key={actor.actorNr}>
                                                <Avatar
                                                    src={avatarUrl}
                                                    alt={t(
                                                        "accessibility.account.profilePicture",
                                                    )}
                                                />
                                                <Text>{actor.name}</Text>
                                                <Badge
                                                    variant="indigo"
                                                    height={8}
                                                    width={width}
                                                    UNSAFE_style={{
                                                        left: 160,
                                                        top: 9,
                                                    }}>
                                                    <Text alignSelf={"center"}>
                                                        {platform === "VR"
                                                            ? "VR"
                                                            : "Web"}
                                                    </Text>
                                                </Badge>
                                                {/* TODO: disabling until teleport feature is in
                                                <ActionButton
                                                    isQuiet
                                                    onPress={() =>
                                                        console.log(
                                                            "Clicked on " +
                                                                actor.name,
                                                        )
                                                    }
                                                    UNSAFE_style={{
                                                        left: "250",
                                                    }}>
                                                    <Crosshairs />
                                                </ActionButton> */}
                                            </Item>
                                        );
                                    })}
                                </ListBox>
                            </Content>
                        </Dialog>
                    </DialogTrigger>
                </View>
            </Flex>
        );
    }

    return (
        <View>
            <Flex
                direction="row"
                alignItems="center"
                position="relative"
                UNSAFE_style={{
                    marginRight: "150px",
                    marginTop: "2px",
                }}>
                {networkManager?.myRoomActorsArray() &&
                    (networkManager.myRoomActorsArray().length <=
                    maxUsersDisplayed
                        ? renderLimitedAvatarView(
                              networkManager?.myRoomActorsArray(),
                          )
                        : renderAvatarDropDownView())}
            </Flex>
        </View>
    );
}
