/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Link,
    ProgressBar,
    Text,
    View,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAssetContext } from "../contexts/AssetContext";

export function StorageQuotaView() {
    const { t } = useTranslation("web");
    const {
        getStorageQuota,
        storageQuotaData,
        yourAssets,
    } = useAssetContext();

    useEffect(() => {
        getStorageQuota();
    }, [yourAssets]);

    if (!storageQuotaData) return;
    const bytesUsedMb = storageQuotaData.bytesUsed / 1000000;
    let bytesUsedString;
    if (bytesUsedMb > 1000) {
        bytesUsedString = t("account.cloudStorage.valueGb", {
            val: (bytesUsedMb / 1000).toFixed(1),
        });
    } else if (bytesUsedMb > 1) {
        bytesUsedString = t("account.cloudStorage.valueMb", {
            val: bytesUsedMb.toFixed(1),
        });
    } else if (bytesUsedMb > 0) {
        bytesUsedString = t("account.cloudStorage.valueKb", {
            val: (storageQuotaData.bytesUsed / 1000).toFixed(0),
        });
    } else {
        // 0 bytes used
        bytesUsedString = "0";
    }

    const bytesLimitGb = storageQuotaData.bytesLimit / 1000000000;
    let bytesLimitString;
    if (bytesLimitGb > 1) {
        bytesLimitString = t("account.cloudStorage.valueGb", {
            val: bytesLimitGb.toFixed(1),
        });
    } else {
        bytesLimitString = t("account.cloudStorage.valueMb", {
            val: (storageQuotaData.bytesLimit / 1000000).toFixed(1),
        });
    }

    const usagePerecentage = storageQuotaData.percentageUsed;

    function alertIcon() {
        const color = (usagePerecentage > 99) ? "negative" : "notice";
        return (
            <Alert size="S" color={color} />
        );
    }

    function alertText() {
        const almostFullText = (
            <Text
                marginY="size-100"
                UNSAFE_style={{
                    fontSize: "0.90em",
                }}>
                {t("account.cloudStorage.warning.almostFull.1")}
                <Link href={t("account.cloudStorage.warning.storageQuota.link")} target="_blank">{t("account.cloudStorage.warning.almostFull.2")}</Link>
                {t("account.cloudStorage.warning.almostFull.3")}
            </Text>
        );

        const fullText = (
            <Text
                marginY="size-100"
                UNSAFE_style={{
                    fontSize: "0.90em",
                }}>
                {t("account.cloudStorage.warning.full.1")}
                <Link href={t("account.cloudStorage.warning.storageQuota.link")} target="_blank">{t("account.cloudStorage.warning.full.2")}</Link>
                {t("account.cloudStorage.warning.full.3")}
            </Text>
        );

        return (
            <View marginTop="size-50">
                {(usagePerecentage > 99) ? fullText : almostFullText}
            </View>
        );
    }

    return (
        <View
            maxWidth="100%"
            marginX="size-200"
            marginY="size-150">
            {/*<View>Your storage is almost full.</View>*/}
            <ProgressBar width="100%" label={t("account.cloudStorage")} value={usagePerecentage} />
            <View marginY="size-150">
                <Flex
                    direction="row"
                    gap="size-100"
                    alignItems="center">
                    {(usagePerecentage > 95) && alertIcon()}
                    <Text
                        UNSAFE_style={{
                            fontSize: "0.90em",
                        }}>
                        {bytesUsedString}{t("account.cloudStorage.of")}{bytesLimitString}
                    </Text>
                </Flex>
                {(usagePerecentage > 95) && alertText()}
            </View>
        </View>
    );
}
