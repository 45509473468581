/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Flex, Text, Image, View } from "@adobe/react-spectrum";
import Settings from "@spectrum-icons/workflow/Settings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AssetTabsView } from "../../components/AssetTabsView";
import { UserProfileView } from "../../components/UserProfileView";
import { useAssetContext } from "../../contexts/AssetContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { usePostMessageContext } from "../../contexts/PostMessageContext";
import { usePollCDO, POLL_INTERVAL } from "../../hooks/usePollCDO";
import appIcon from "../../images/rw_appicon_noshadow_128.png";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";

export function StartScreen() {
    const { t } = useTranslation(["common", "vr"]);
    const { locale } = useLocaleContext();
    const { accessToken, logIn, logOut, userProfile, avatarUrl, imsReady } =
        useHi5UserContext();
    const { postMessageBusApi } = usePostMessageContext();

    const {
        yourAssets,
        sharedWithYouAssets,
        getYourAssets,
        getSharedWithYouAssets,
        online,
    } = useAssetContext();

    const { pollForUpdates } = usePollCDO();

    useEffect(() => {
        if (postMessageBusApi && imsReady && !userProfile) {
            postMessageBusApi.showDialog("log-in-check");
        }
    }, [postMessageBusApi, imsReady, userProfile]);

    useEffect(() => {
        if (!online) return;
        getYourAssets(true);
        getSharedWithYouAssets(true);
    }, [online]);

    useEffect(() => {
        if (userProfile && avatarUrl && postMessageBusApi) {
            console.log(
                "Post User Profile",
                userProfile.displayName,
                avatarUrl,
            );
            postMessageBusApi.adobeUser(
                userProfile.displayName ||
                    userProfile.first_name + " " + userProfile.last_name,
                avatarUrl,
            );
        }

        if (userProfile && postMessageBusApi && accessToken) {
            console.log("User is authorized");
            //for testing till in production
            import("@src/dev-config").then(({config}) => {
                postMessageBusApi.userHasAuth(config.tokenOverride);
            }).catch(() => {
                postMessageBusApi.userHasAuth(accessToken);
            })
        }
    }, [userProfile, avatarUrl, postMessageBusApi]);

    function onAssetClick(urn: string) {
        if (postMessageBusApi)
            postMessageBusApi.showDialog("access-check", `urn=${urn}`);
    }

    function logOutAndClearCookies() {
        if (postMessageBusApi) {
            postMessageBusApi.clearCookies();
            postMessageBusApi.signOutVR();
        }
        logOut();
    }

    // Poll for updates
    useEffect(() => {
        const interval = setInterval(async function () {
            await pollForUpdates(false);
        }, POLL_INTERVAL);
        return () => clearInterval(interval);
    }, [accessToken, yourAssets, sharedWithYouAssets]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                paddingTop: "10vh",
                paddingBottom: "4vh",
                paddingLeft: "4vw",
                paddingRight: "2vw",
                boxSizing: "border-box",
            }}>
            <Flex
                direction="row"
                height="100%"
                width="100%"
                gap="4vw"
                justifyContent="space-between">
                <Flex
                    direction="column"
                    width="22%"
                    height="100%"
                    // justifyContent="space-between"
                >
                    <Flex
                        direction="row"
                        width="100%"
                        justifyContent="start"
                        alignItems="center"
                        gap="size-400">
                        <Image
                            width="128px"
                            height="128px"
                            minWidth="128px"
                            minHeight="128px"
                            alt={t("common:header.appIcon")}
                            src={appIcon}
                            UNSAFE_style={{
                                borderRadius: "8px",
                            }}
                        />
                        <View>
                            <div>
                                <div style={{lineHeight: "2em"}}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "2.5em",
                                        }}>
                                        {t("vr:header.appTitle.1")}
                                    </Text>
                                </div>
                                <div>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "2.5em",
                                        }}>
                                        {t("vr:header.appTitle.2")}
                                    </Text>
                                </div>
                            </div>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "1.75em",
                                    color: "rgb(150,150,150)",
                                }}>
                                {t("vr:header.version")}
                            </Text>
                        </View>
                    </Flex>
                    {/* <View> <--- removing for now, till design is updated
                        <Flex direction="column" gap="10px">
                            <ActionButton>
                                {t("common:startScreen.tutorial")}
                            </ActionButton>
                            <ActionButton>
                                {t("common:startScreen.sampleScene")}
                            </ActionButton>
                            <ActionButton>
                                {t("common:preferences.legal")}
                            </ActionButton>
                            <ActionButton>
                                {t("preferences.legal")}
                            </ActionButton>
                            <ActionButton
                                onPress={() => {
                                    if (postMessageBusApi)
                                        postMessageBusApi.showAppSettings();
                                }}>
                                <Settings />
                                <Text>{t("common:account.preferences")}</Text>
                            </ActionButton>
                        </Flex>
                    </View> */}
                    <View width="100%" paddingTop="850px">
                        <Flex direction="column" gap="10px">
                            <UserProfileView
                                userProfile={userProfile}
                                avatarUrl={avatarUrl}
                                trimStrings={true}
                                isForVr={true}
                            />
                            <Flex
                                direction="row"
                                justifyContent="space-between"
                                gap="8px">
                                <Button
                                    onPress={
                                        userProfile
                                            ? logOutAndClearCookies
                                            : logIn
                                    }
                                    marginTop="2vh"
                                    width="100%"
                                    height="3.2vh"
                                    variant="primary"
                                    style="fill"
                                    UNSAFE_style={{
                                        borderRadius: "100px",
                                    }}>
                                    <Text
                                        UNSAFE_style={{
                                            fontSize: "1.5em",
                                        }}>
                                        {userProfile
                                            ? t("common:account.signOut")
                                            : t("vr:account.signIn")}
                                    </Text>
                                </Button>
                                <Button
                                    variant="primary"
                                    style="fill"
                                    marginTop="2vh"
                                    marginStart="10px"
                                    width="2.8vw"
                                    height="3.2vh"
                                    onPress={() => {
                                        if (postMessageBusApi)
                                            postMessageBusApi.showAppSettings();
                                    }}
                                    UNSAFE_style={{
                                        borderRadius: "100px",
                                    }}>
                                    <Settings
                                        aria-label={t(
                                            "common:account.preferences",
                                        )}
                                    />
                                </Button>
                            </Flex>
                        </Flex>
                    </View>
                </Flex>
                <Flex
                    direction="column"
                    width="78%"
                    height="100%"
                    marginStart="20px">
                    {/*<View>
                        <Header
                            UNSAFE_style={{
                                fontSize: "1.25em",
                            }}
                            marginBottom="4vh">
                            Active reviews
                        </Header>
                        <Flex
                            direction="row"
                            width="100%"
                            justifyContent="center"
                            marginY="4vh">
                            <Text>There are currently no active reviews.</Text>
                        </Flex>
                    </View>*/}
                    <View>
                        <AssetTabsView
                            accessToken={accessToken}
                            onAssetClick={onAssetClick}
                            yourAssets={yourAssets}
                            sharedWithYouAssets={sharedWithYouAssets}
                            isForVr={true}
                            locale={locale}
                        />
                    </View>
                </Flex>
            </Flex>
        </div>
    );
}
