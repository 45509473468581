/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { AssetMetadata } from "../contexts/AssetContext";

export enum SortType {
    recent = "recent",
    name = "name",
}

export function sortByRecent(a: AssetMetadata, b: AssetMetadata) {
    if (!(a.modifyDate && b.modifyDate)) return 0;
    if (a.modifyDate > b.modifyDate) {
        return -1;
    } else {
        return 1;
    }
}

export function sortByName(a: AssetMetadata, b: AssetMetadata) {
    if (!(a.displayName && b.displayName)) return 0;
    const displayNameA = a.displayName.toUpperCase();
    const displayNameB = b.displayName.toUpperCase();
    if (displayNameA < displayNameB) {
        return -1;
    } else {
        return 1;
    }
}
