/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { MessagingContextProvider } from "@shared/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./i18n";
import { App } from "./App";
import { AEP_ENDPOINT, ROUTER_BASE_PATH } from "./config";
import { AssetProvider } from "./contexts/AssetContext";
import { HI5UserProvider } from "./contexts/HI5UserProvider";
import { LocaleProvider } from "./contexts/LocaleContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { PostMessageProvider } from "./contexts/PostMessageContext";
import { ThemeProvider } from "./contexts/ThemeContext";

const analyticsScript = document.createElement("script");
analyticsScript.setAttribute("src", AEP_ENDPOINT);
analyticsScript.setAttribute("type", "text/javascript");
analyticsScript.setAttribute("async", "true");
document.body.appendChild(analyticsScript);

document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("app-root");
    if (container) {
        const root = ReactDOM.createRoot(container);
        root.render(
            <BrowserRouter basename={ROUTER_BASE_PATH}>
                <ThemeProvider>
                    <LocaleProvider>
                        <HI5UserProvider>
                            <MessagingContextProvider>
                                <NetworkProvider>
                                    <PostMessageProvider>
                                        <AssetProvider>
                                            <App />
                                        </AssetProvider>
                                    </PostMessageProvider>
                                </NetworkProvider>
                            </MessagingContextProvider>
                        </HI5UserProvider>
                    </LocaleProvider>
                </ThemeProvider>
            </BrowserRouter>,
        );
    }
});
