/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/


import { PropsWithChildren, createContext, useContext, useMemo } from "react";

import { NetworkManager } from "@src/networking/NetworkManager";
import { NetworkVoiceManager } from "@src/networking/NetworkVoiceManager";

export interface NetworkContextValue {
    networkManager: NetworkManager | null;
    voiceManager: NetworkVoiceManager | null;
}

export const NetworkContext = createContext<NetworkContextValue>(
    {} as NetworkContextValue,
);

export function useNetworkContext() {
    const context = useContext(NetworkContext);
    if (!context) {
        throw new Error(
            "useNetworkContext must be used within a NetworkContext.Provider",
        );
    }
    return context;
}

export function NetworkProvider({ children }: PropsWithChildren) {
    const networkManager = useMemo(() => new NetworkManager(), []);
    const voiceManager = useMemo(() => new NetworkVoiceManager(), []);

    return (
        <NetworkContext.Provider value={{ networkManager, voiceManager }}>
            {children}
        </NetworkContext.Provider>
    );
}
