/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useAssetContext } from "../contexts/AssetContext";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";


import type { AssetMetadata } from "../contexts/AssetContext";

export const POLL_INTERVAL = 5000;

export function usePollCDO() {
    const { accessToken } = useHi5UserContext();

    const {
        yourAssets,
        yourAssetsHasChange,
        setYourAssets,
        sharedWithYouAssets,
        setSharedWithYouAssets,
        getYourAssets,
        getSharedWithYouAssets,
        getComponentData,
        setYourAssetsHasChange,
        setSharedWithYouAssetsHasChange,
    } = useAssetContext();

    function getAssetsDiff(assets1: AssetMetadata[], assets2: AssetMetadata[]) {
        const assetsDiff = assets1.filter(function (asset1) {
            return !assets2.some(function (asset2) {
                return (
                    asset1.urn == asset2.urn &&
                    asset1.displayName == asset2.displayName &&
                    asset1.thumbnailUrl !== "undefined"
                );
            });
        });
        return assetsDiff;
    }

    async function hasUploadInProgress(asset: AssetMetadata) {
        const componentData = await getComponentData(asset.urn, false, true);
        if (componentData === undefined) {
            return true;
        }
        return false;
    }

    async function pollForUpdates(isUploading?: boolean): Promise<void> {
        if (!accessToken || isUploading || yourAssetsHasChange) return;

        const polledAssets = await getYourAssets(false);
        const polledSharedAssets = await getSharedWithYouAssets(false);
        if (
            !polledSharedAssets ||
            !sharedWithYouAssets ||
            !polledAssets ||
            !yourAssets
        )
            return;

        // find assets in polledAssets but not in currentAssets
        const polledAssetsDiff = getAssetsDiff(polledAssets, yourAssets);
        const polledSharedAssetsDiff = getAssetsDiff(
            polledSharedAssets,
            sharedWithYouAssets,
        );

        // find assets in currentAssets but not in polledAssets
        const currentAssetsDiff = getAssetsDiff(yourAssets, polledAssets);
        const currentSharedAssetsDiff = getAssetsDiff(
            sharedWithYouAssets,
            polledSharedAssets,
        );

        if (polledAssetsDiff.length > 0 || currentAssetsDiff.length > 0) {
            let uploadInProgress = false;
            if (polledAssetsDiff.length > 0) {
                uploadInProgress = await hasUploadInProgress(
                    polledAssetsDiff[0],
                );
            }

            if (!uploadInProgress) {
                setYourAssets(polledAssets);
                setYourAssetsHasChange(true);
            }
        }

        if (
            polledSharedAssetsDiff.length > 0 ||
            currentSharedAssetsDiff.length > 0
        ) {
            setSharedWithYouAssets(polledSharedAssets);
            setSharedWithYouAssetsHasChange(true);
        }
    }
    return { pollForUpdates };
}
