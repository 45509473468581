/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { BoundingBox, Camera, Mesh, Scene } from "@babylonjs/core";
import { EngineStore } from "@babylonjs/core/Engines/engineStore";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image as ImageGUI } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import Arrows from "../images/arrows.png";
import DefaultAvatarGrayscale from "../images/default-avatar-grayscale.png";
import DefaultAvatar from "../images/default-avatar.png";
import MicMuteDark from "../images/mic-mute-dark.png";
import MicMuteLight from "../images/mic-mute-light.png";

export const AFK_COLOR = "#B2B2B2";
const LIGHT_FOREGROUND = "#FFFFFF";
const DARK_FOREGROUND = "#3F3F3F";
const FOREGROUND_COLOR_MAP: Record<string, string> = {
    "#008CB8": LIGHT_FOREGROUND,
    "#E34850": LIGHT_FOREGROUND,
    "#EDCC00": DARK_FOREGROUND,
    "#4BCCA2": DARK_FOREGROUND,
    "#F69500": DARK_FOREGROUND,
    "#B247C2": LIGHT_FOREGROUND,
    "#00C7FF": DARK_FOREGROUND,
    "#7E4BF3": LIGHT_FOREGROUND,
    "#B2B2B2": DARK_FOREGROUND,
};

const NAME_TAG_RATIO = 2.5;

const MIN_SIZE_DISTANCE = 16;
const MAX_SIZE_DISTANCE = 0.2;
const MIN_SIZE = 187;
const MAX_SIZE = 250;

const ARROW_WIDTH = 107;
const ARROW_HEIGHT = 128;
// mapping of arrow color to the source image left and top
// coordinates to render the correct colored arrow
const ARROW_SOURCE_MAPPING: Record<string, [number, number]> = {
    "#008CB8": [ARROW_WIDTH, ARROW_HEIGHT], // Dark Blue
    "#E34850": [0, 0], // Red
    "#EDCC00": [2 * ARROW_WIDTH, 0], // Yellow
    "#4BCCA2": [3 * ARROW_WIDTH, 0], // Turquoise
    "#F69500": [ARROW_WIDTH, 0], // Orange
    "#B247C2": [3 * ARROW_WIDTH, ARROW_HEIGHT], // Magenta
    "#00C7FF": [0, ARROW_HEIGHT], // Light Blue
    "#7E4BF3": [2 * ARROW_WIDTH, ARROW_HEIGHT], // Purple
    "#B2B2B2": [0, 2 * ARROW_HEIGHT], // AFK
};
const ARROW_DIRECTIONS = {
    NONE: 0,
    TOP: 1,
    BOTTOM: 2,
    LEFT: 3,
    RIGHT: 4,
};

export class AvatarsManager {
    private avatarMap: Record<number, Mesh> = {}; // mapping of actors to their avatar representation
    private remoteAvatarMap: Record<number, Mesh> = {}; // mapping of actors to their ghost representation; this is not visible to the user and is used to keep track of the users actual 3D position in space
    private colorsMap: Record<number, string> = {}; // mapping of actors to their assigned colors
    private profilePicMap: Record<number, [string, string]> = {}; // mapping of actors to their base64 profile picture URLs

    private availableColors: string[] = [
        "#008CB8", // Dark Blue
        "#E34850", // Red
        "#EDCC00", // Yellow
        "#4BCCA2", // Turquoise
        "#F69500", // Orange
        "#B247C2", // Magenta
        "#00C7FF", // Light Blue
        "#7E4BF3", // Purple
    ];

    private scene: Scene;
    camera: Camera;
    private viewportWidth: number;
    private viewportHeight: number;

    // used to throttle updates to avatar positions
    private waiting: boolean = false;

    constructor(scene: Scene) {
        this.scene = scene;
        this.camera = scene.cameras[0];

        const engine = scene.getEngine();

        // Fixes error thrown for invalid engine
        EngineStore._LastCreatedScene = scene;
        EngineStore.Instances.push(engine);

        this.viewportWidth = engine.getRenderWidth();
        this.viewportHeight = engine.getRenderHeight();
    }

    /**
     * @summary creates a new mesh for the avatar representation of an actor in Babylon scene
     *
     * @param actorNr
     * @param displayName
     * @param avatarUrl
     * @param color
     */
    async spawnAvatar(
        actorNr: number,
        displayName: string,
        avatarUrl: string,
        color: string,
    ) {
        console.log("spawning avatar: ", displayName);
        const plane = MeshBuilder.CreatePlane(displayName, {
            width: 1,
            height: 1 / NAME_TAG_RATIO,
        });
        plane.isPickable = false;
        plane.billboardMode = 7;
        this.avatarMap[actorNr] = plane;
        const advancedTexture = AdvancedDynamicTexture.CreateForMesh(
            plane,
            1024,
            1024 / NAME_TAG_RATIO,
        );

        // GUI components
        const textContainer = new Rectangle("nameTagContainer");
        textContainer.thickness = 0;
        textContainer.background = color;
        textContainer.cornerRadius = 20;
        textContainer.scaleX = 2.8;
        textContainer.scaleY = 2.8;

        // name tag
        // truncate name if it exceeds 18 characters
        if (displayName.length >= 18) {
            displayName = displayName.substring(0, 18) + "...";
        }
        const text = new TextBlock("nameTag: " + actorNr, displayName);
        text.fontSizeInPixels = 20;
        text.setPadding(8, 30, 8, 75);

        textContainer.adaptHeightToChildren = true;
        textContainer.adaptWidthToChildren = true;
        text.resizeToFit = true;
        text.color = FOREGROUND_COLOR_MAP[color];
        text.fontFamily = "adobe-clean";

        const imgContainer = new Rectangle("imgContainer");
        imgContainer.thickness = 2;
        imgContainer.color = FOREGROUND_COLOR_MAP[color];
        imgContainer.cornerRadius = 20;
        imgContainer.left = 6;
        imgContainer.heightInPixels = 30;
        imgContainer.widthInPixels = 30;
        imgContainer.horizontalAlignment = 0;
        // profile picture
        // NOTE: BabylonJS throws a CORS error when loading the profile picture URL
        // so we convert it to base64 format to bypass the error
        let colorImg = DefaultAvatar;
        let grayscaleImg = DefaultAvatarGrayscale;
        try {
            [colorImg, grayscaleImg] = (await this.getBase64Images(
                avatarUrl,
            )) as [string, string];
        } catch (error) {
            console.error("Error loading profile picture: ", error);
        }
        this.profilePicMap[actorNr] = [colorImg, grayscaleImg];
        const image = new ImageGUI("profile", this.profilePicMap[actorNr][0]);
        image.stretch = ImageGUI.STRETCH_NONE;
        image.autoScale = true;
        image.scaleX = 0.1;
        image.scaleY = 0.1;

        // container for audio states
        const audioStateContainer = new Rectangle("audioContainer");
        audioStateContainer.thickness = 0;
        audioStateContainer.left = 41;
        audioStateContainer.top = 1;
        audioStateContainer.heightInPixels = 30;
        audioStateContainer.widthInPixels = 30;
        audioStateContainer.horizontalAlignment = 0;

        let mutedImg;
        if (FOREGROUND_COLOR_MAP[color] === LIGHT_FOREGROUND) {
            mutedImg = new ImageGUI("muted", MicMuteLight);
        } else {
            mutedImg = new ImageGUI("muted", MicMuteDark);
        }
        mutedImg.scaleX = 0.6;
        mutedImg.scaleY = 0.6;
        mutedImg.stretch = ImageGUI.STRETCH_UNIFORM;
        audioStateContainer.addControl(mutedImg);

        // container for direction arrow
        const directionContainer = new Rectangle("directionContainer");
        directionContainer.thickness = 0;

        const arrowImg = new ImageGUI("arrow", Arrows);
        arrowImg.widthInPixels = 107;
        arrowImg.heightInPixels = 128;
        arrowImg.sourceWidth = 107;
        arrowImg.sourceHeight = 128;
        arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[color][0];
        arrowImg.sourceTop = ARROW_SOURCE_MAPPING[color][1];
        arrowImg.isVisible = false;
        arrowImg.scaleX = 0.8;
        arrowImg.scaleY = 0.8;

        directionContainer.addControl(arrowImg);

        textContainer.addControl(text);
        imgContainer.addControl(image);
        textContainer.addControl(imgContainer);
        textContainer.addControl(audioStateContainer);
        advancedTexture.addControl(directionContainer);
        advancedTexture.addControl(textContainer);

        plane.renderingGroupId = 2;

        const remoteAvatar = plane.clone();
        remoteAvatar.isVisible = false;
        this.remoteAvatarMap[actorNr] = remoteAvatar;

        this.scaleAvatar(plane);
        this.updateOffscreenVisual(plane);
    }

    updateAvatarPosition(actorNr: number, position: number[]) {
        if (!this.waiting) {
            window.requestAnimationFrame(() => {
                const avatar: Mesh = this.avatarMap[actorNr];
                const remoteAvatar: Mesh = this.remoteAvatarMap[actorNr];
                if (avatar && remoteAvatar) {
                    const newPosition = new Vector3(
                        position[0],
                        position[1],
                        position[2],
                    );
                    avatar.position = newPosition.clone();
                    remoteAvatar.position = newPosition.clone();
                    this.scaleAvatar(avatar);
                    this.updateOffscreenVisual(avatar);
                }
                this.waiting = false;
            });
            this.waiting = true;
        }
    }

    scaleAvatar(avatar: Mesh) {
        // 1. get screen space height of avatar
        const boundingInfo = avatar.getBoundingInfo().boundingBox;
        const height = this.getScreenSpaceSize(boundingInfo).height;
        if (
            height === Number.POSITIVE_INFINITY ||
            height === Number.NEGATIVE_INFINITY
        )
            return;
        const distance = Vector3.Distance(
            this.camera.position,
            avatar.position,
        );

        // 2. compare height to min and max sizes and determine scale factor for screen space height
        // NOTE: this calculates the scale FACTOR not the actual scale value
        let scale = 1;
        if (height == 0) return;
        if (distance > MIN_SIZE_DISTANCE || height <= MIN_SIZE) {
            scale = MIN_SIZE / height;
        } else if (distance < MAX_SIZE_DISTANCE || height >= MAX_SIZE) {
            scale = MAX_SIZE / height;
        } else {
            // LERP based on distance from camera; scale distance to 0 to 1 for LERP parameter
            const t =
                (distance - MIN_SIZE_DISTANCE) /
                (MAX_SIZE_DISTANCE - MIN_SIZE_DISTANCE);
            const size = this.lerp(MIN_SIZE, MAX_SIZE, t);
            scale = size / height;
        }

        // 3. apply scale factor to mesh by multiplying current scale by the calculated factor
        if (scale > Number.EPSILON) {
            avatar.scaling = avatar.scaling.multiplyByFloats(
                scale,
                scale,
                scale,
            );
            return;
        }
    }

    updateOffscreenVisual(avatar: Mesh) {
        const distance = Vector3.Distance(
            this.camera.position,
            avatar.position,
        );

        // 1. convert avatar position from world space to screen space
        const screenPos = this.worldToScreen(avatar.position);

        // 2. clamp screen space position to viewport bounds (0 to 1) so avatar stays on edge of screen
        // note that this the position of the center of the name tag
        const clamepdScreenPos = new Vector3(
            Math.max(0, Math.min(screenPos.x, this.viewportWidth)),
            Math.max(0, Math.min(screenPos.y, this.viewportHeight)),
            screenPos.z >= 1 ? 0.998 : screenPos.z, // 0.998 to render in front of user
        );

        // 3. adjust for name tag width / height so that the name tag is fully visible at the edge of the screen
        // and modify arrow UI based on offscreen position
        const boundingInfo = avatar.getBoundingInfo().boundingBox;
        // calculate screen space height and width of name tag based on bounding box world space coordinates
        const { width, height } = this.getScreenSpaceSize(boundingInfo);
        // if name tag position has been clamped to edge of viewport, then adjust position with width/height offset so name tag is fully visible
        this.updateArrowDirection(ARROW_DIRECTIONS.NONE, avatar);
        if (clamepdScreenPos.x <= 0) {
            this.updateArrowDirection(ARROW_DIRECTIONS.LEFT, avatar);
            clamepdScreenPos.x += width / 2;
        } else if (clamepdScreenPos.x >= this.viewportWidth) {
            this.updateArrowDirection(ARROW_DIRECTIONS.RIGHT, avatar);
            clamepdScreenPos.x -= width / 2;
        }
        if (clamepdScreenPos.y <= 0) {
            this.updateArrowDirection(ARROW_DIRECTIONS.TOP, avatar);
            clamepdScreenPos.y += height / 2;
        } else if (clamepdScreenPos.y >= this.viewportHeight) {
            this.updateArrowDirection(ARROW_DIRECTIONS.BOTTOM, avatar);
            clamepdScreenPos.y -= height / 2;
        }
        if (screenPos.z >= 1) {
            // if avatar is behind the camera, then render at bottom of the screen
            clamepdScreenPos.y = this.viewportHeight - height / 2;
            this.updateArrowDirection(ARROW_DIRECTIONS.BOTTOM, avatar);
        }

        // 4. convert avatar clamepd screen space position back to world space
        const clampedWorldPos = this.screenToWorld(clamepdScreenPos);

        // 5. apply modified world space position to avatar to ensure "offscreen" avatars stay on the edge of the screen
        avatar.position = clampedWorldPos;
    }

    updateArrowDirection(direction: number, avatar: Mesh) {
        const texture = this.getTexture(avatar);
        if (!texture) {
            console.error("No texture found for avatar");
            return;
        }

        const arrowImg = this.getArrowImg(texture);
        if (!arrowImg) {
            console.error("No arrow image found for avatar");
            return;
        }

        if (direction === ARROW_DIRECTIONS.NONE) {
            arrowImg.isVisible = false;
        } else {
            arrowImg.isVisible = true;
            switch (direction) {
                case ARROW_DIRECTIONS.TOP:
                    arrowImg.horizontalAlignment =
                        Control.HORIZONTAL_ALIGNMENT_CENTER;
                    arrowImg.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
                    arrowImg.rotation = 0;
                    break;
                case ARROW_DIRECTIONS.BOTTOM:
                    arrowImg.horizontalAlignment =
                        Control.HORIZONTAL_ALIGNMENT_CENTER;
                    arrowImg.verticalAlignment =
                        Control.VERTICAL_ALIGNMENT_BOTTOM;
                    arrowImg.rotation = 180 * (Math.PI / 180);
                    break;
                case ARROW_DIRECTIONS.LEFT:
                    arrowImg.horizontalAlignment =
                        Control.HORIZONTAL_ALIGNMENT_LEFT;
                    arrowImg.verticalAlignment =
                        Control.VERTICAL_ALIGNMENT_CENTER;
                    arrowImg.rotation = -90 * (Math.PI / 180);
                    break;
                case ARROW_DIRECTIONS.RIGHT:
                    arrowImg.horizontalAlignment =
                        Control.HORIZONTAL_ALIGNMENT_RIGHT;
                    arrowImg.verticalAlignment =
                        Control.VERTICAL_ALIGNMENT_CENTER;
                    arrowImg.rotation = 90 * (Math.PI / 180);
                    break;
                default:
                    break;
            }
        }
    }

    removeAvatar(actorNr: number) {
        const avatar = this.avatarMap[actorNr];
        if (avatar) {
            avatar.geometry?.dispose();
            avatar.material?.dispose();
            avatar.dispose();
            delete this.avatarMap[actorNr];
        }
    }

    /**
     * @summary assigns a random color to a user from the availableColors list.
     * if the user already has a color assigned, it returns the assigned color.
     * once a color is assigned it gets pushed to the back of the list so that colors are
     * reused after >8 players.
     *
     * @param actorNr
     * @returns color assigned to user
     */
    assignUserColor(actorNr: number) {
        // if user already has a color dont assign a new one
        const userColor = this.colorsMap[actorNr];
        if (userColor) {
            const event = new CustomEvent("onUserColorAssigned", {
                detail: { actorNr, color: userColor },
            });
            window.dispatchEvent(event);
            return userColor;
        }

        const color = this.availableColors.shift();
        if (color) {
            this.availableColors.push(color);
            this.colorsMap[actorNr] = color;
            const event = new CustomEvent("onUserColorAssigned", {
                detail: { actorNr, color },
            });
            window.dispatchEvent(event);
            return color;
        } else {
            return undefined;
        }
    }

    /**
     * @summary called when the host assigns a color to a user to update user's local color map
     * @param actorNr actor number of the user whose color is being updated
     * @param color color assigned to the user
     */
    updateUserColor(actorNr: number, color: string) {
        if (color) {
            this.colorsMap[actorNr] = color;
            this.availableColors = this.availableColors.filter(
                (c) => c !== color,
            );
            this.availableColors.push(color);
            const event = new CustomEvent("onUserColorAssigned", {
                detail: { actorNr, color },
            });
            window.dispatchEvent(event);
        }
    }

    /**
     * @summary called when a user leaves the room; frees up the color assigned to the user
     *
     * @param actorNr actor number of the user who left the room
     */
    releaseUserColor(actorNr: number) {
        const color = this.colorsMap[actorNr];
        if (color) {
            delete this.colorsMap[actorNr];

            const event = new CustomEvent("onUserColorAssigned", {
                detail: { actorNr, color: null },
            });
            window.dispatchEvent(event);
        } else {
            console.error("No color assigned to user: " + actorNr);
        }
    }

    updateIsIdleState(actorNr: number, isIdle: boolean) {
        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        const texture = this.getTexture(avatar);
        if (!texture) {
            console.error("No texture found for avatar: " + actorNr);
            return;
        }

        const nameTagContainer = this.getNameTagContainer(texture);
        const imgContainer = this.getImgContainer(texture);
        const text = this.getTextElement(texture, actorNr);
        const mutedImg = this.getMutedImg(texture);
        const profileImg = this.getProfileImg(texture);
        const arrowImg = this.getArrowImg(texture);
        if (
            !nameTagContainer ||
            !imgContainer ||
            !text ||
            !mutedImg ||
            !profileImg
        ) {
            console.error(
                "One or more GUI elements not found for actor: " + actorNr,
            );
            return;
        }

        if (isIdle) {
            const foregroundColor = FOREGROUND_COLOR_MAP[AFK_COLOR];
            text.color = foregroundColor;
            imgContainer.color = foregroundColor;
            nameTagContainer.background = AFK_COLOR;
            mutedImg.source = MicMuteDark;
            profileImg.source = this.profilePicMap[actorNr][1];
            arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[AFK_COLOR][0];
            arrowImg.sourceTop = ARROW_SOURCE_MAPPING[AFK_COLOR][1];
        } else {
            const color = this.colorsMap[actorNr];
            const foregroundColor = FOREGROUND_COLOR_MAP[color];
            text.color = foregroundColor;
            imgContainer.color = foregroundColor;
            nameTagContainer.background = color;
            mutedImg.source =
                foregroundColor === LIGHT_FOREGROUND
                    ? MicMuteLight
                    : MicMuteDark;
            profileImg.source = this.profilePicMap[actorNr][0];
            arrowImg.sourceLeft = ARROW_SOURCE_MAPPING[color][0];
            arrowImg.sourceTop = ARROW_SOURCE_MAPPING[color][1];
        }
    }

    updateIsMutedState(actorNr: number, isMuted: boolean) {
        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        const texture = this.getTexture(avatar);
        if (!texture) {
            console.error("No texture found for avatar: " + actorNr);
            return;
        }

        const nameTagContainer = this.getNameTagContainer(texture);
        const audioStateContainer = this.getAudioContainer(texture);
        const text = this.getTextElement(texture, actorNr);
        if (!nameTagContainer || !audioStateContainer || !text) {
            console.error(
                "One or more GUI elements not found for actor: " + actorNr,
            );
            return;
        }

        if (isMuted) {
            audioStateContainer.isVisible = true;
            text.setPadding(8, 30, 8, 75);
        } else {
            audioStateContainer.isVisible = false;
            text.setPadding(8, 30, 8, 45);
        }
    }

    /**
     * @summary updates name tag visual based on IsTalking state
     * renders white border when user is talking
     *
     * @param actorNr
     * @param isTalking
     */
    updateIsTalkingState(actorNr: number, isTalking: boolean) {
        const avatar = this.avatarMap[actorNr];
        if (!avatar) {
            console.error("No avatar found for actor: " + actorNr);
            return;
        }

        const texture = this.getTexture(avatar);
        if (!texture) {
            console.error("No texture found for avatar: " + actorNr);
            return;
        }

        const nameTagContainer = this.getNameTagContainer(texture);
        const imgContainer = this.getImgContainer(texture);
        if (!nameTagContainer || !imgContainer) {
            console.error(
                "One or more GUI elements not found for actor: " + actorNr,
            );
            return;
        }

        if (isTalking) {
            nameTagContainer.thickness = 3;
            nameTagContainer.color = LIGHT_FOREGROUND;
            imgContainer.left = 3;
        } else {
            nameTagContainer.thickness = 0;
            imgContainer.left = 6;
        }
    }

    /**
     * @summary checks whether an avatar exists for a given actor number
     *
     * @param actorNr
     * @returns boolean indicating whether an avatar exists for the actor number
     */
    hasAvatar(actorNr: number) {
        return this.avatarMap[actorNr];
    }

    /**
     * @summary converts an image URL to base64 format and also gets a grayscale version of the image
     * @param avatarUrl
     * @returns [base64 color image, base64 grayscale image]
     */
    async getBase64Images(avatarUrl: string) {
        const img = new Image();
        img.src = avatarUrl;
        img.crossOrigin = "anonymous";
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (ctx) {
            return new Promise((resolve, reject) => {
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    // render color image
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const base64Color = canvas.toDataURL();

                    // render grayscale image
                    ctx.filter = "grayscale(1)";
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const base64Grayscale = canvas.toDataURL();
                    resolve([base64Color, base64Grayscale]);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            });
        }
        return "";
    }

    lerp(a: number, b: number, t: number) {
        return a + t * (b - a);
    }

    worldToScreen(vector: Vector3) {
        const viewport = this.camera.viewport.toGlobal(
            this.viewportWidth,
            this.viewportHeight,
        );
        return Vector3.Project(
            vector, // vector to project
            Matrix.Identity(), // world matrix
            this.scene.getTransformMatrix(), // transform matrix
            viewport, // viewport
        );
    }

    screenToWorld(vector: Vector3) {
        return Vector3.Unproject(
            vector, // vector to unproject
            this.viewportWidth, // viewport width
            this.viewportHeight, // viewport height
            Matrix.Identity(), // world matrix
            this.scene.getViewMatrix(), // view matrix
            this.scene.getProjectionMatrix(), // projection matrix
        );
    }

    getScreenSpaceSize(boundingInfo: BoundingBox): {
        width: number;
        height: number;
    } {
        let minX = Number.POSITIVE_INFINITY,
            minY = Number.POSITIVE_INFINITY,
            maxX = Number.NEGATIVE_INFINITY,
            maxY = Number.NEGATIVE_INFINITY;
        for (const vertex of boundingInfo.vectorsWorld) {
            const temp = this.worldToScreen(vertex);
            if (minX > temp.x) minX = temp.x;
            if (maxX < temp.x) maxX = temp.x;
            if (minY > temp.y) minY = temp.y;
            if (maxY < temp.y) maxY = temp.y;
        }

        return { width: maxX - minX, height: maxY - minY };
    }

    getTexture(avatar: Mesh) {
        return avatar.material?.getActiveTextures()[0] as AdvancedDynamicTexture;
    }

    getNameTagContainer(texture: AdvancedDynamicTexture) {
        return texture.getControlByName("nameTagContainer") as Rectangle;
    }

    getImgContainer(texture: AdvancedDynamicTexture) {
        return texture.getControlByName("imgContainer") as Rectangle;
    }

    getAudioContainer(texture: AdvancedDynamicTexture) {
        return texture.getControlByName("audioContainer") as Rectangle;
    }

    getDirectionContainer(texture: AdvancedDynamicTexture) {
        return texture.getControlByName("directionContainer") as Rectangle;
    }

    getTextElement(texture: AdvancedDynamicTexture, actorNr: number) {
        return this.getNameTagContainer(texture).getChildByName(
            "nameTag: " + actorNr,
        );
    }

    getMutedImg(texture: AdvancedDynamicTexture) {
        return this.getAudioContainer(texture)?.getChildByName(
            "muted",
        ) as ImageGUI;
    }

    getProfileImg(texture: AdvancedDynamicTexture) {
        return this.getImgContainer(texture)?.getChildByName(
            "profile",
        ) as ImageGUI;
    }

    getArrowImg(texture: AdvancedDynamicTexture) {
        return this.getDirectionContainer(texture)?.getChildByName(
            "arrow",
        ) as ImageGUI;
    }

    getAvatarMap() {
        return this.avatarMap;
    }

    getRemoteAvatar(actorNr: number) {
        return this.remoteAvatarMap[actorNr];
    }
}
