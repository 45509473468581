/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Image, Text, View } from "@adobe/react-spectrum";
import Globe from "@spectrum-icons/workflow/Globe";
import UserGroup from "@spectrum-icons/workflow/UserGroup";
import { useTranslation } from "react-i18next";

import { AssetActionMenu } from "./AssetActionMenu";
import { TabType } from "./AssetTabsView";
import { ProgressCircleView } from "./ProgressCircleView";
import noThumbnail from "../images/no-thumbnail.svg";
import {
    getTrimmedString,
    getRelativeFormattedTime,
} from "../util/StringUtils";

import type { AssetMetadata } from "../contexts/AssetContext";

interface AssetViewProps {
    data: AssetMetadata;
    isForVr: boolean;
    tabType: TabType;
    locale: string;
}

export function AssetGridView({
    data,
    isForVr,
    tabType,
    locale,
}: AssetViewProps) {
    const { t } = useTranslation("common");

    function collaborators() {
        if (data.collaborators === -1) {
            return (
                <Flex
                    direction="row"
                    gap={isForVr ? "size-100" : "size-50"}
                    alignItems="center">
                    <Globe
                        aria-label={t("accessibility.asset.public")}
                        size={isForVr ? "M" : "XS"}
                    />
                </Flex>
            );
        } else {
            return (
                <Flex
                    direction="row"
                    gap={isForVr ? "size-100" : "size-50"}
                    alignItems="center">
                    <UserGroup
                        aria-label={t("accessibility.asset.collaborators")}
                        size={isForVr ? "M" : "XS"}
                    />
                    <Text
                        UNSAFE_style={
                            isForVr
                                ? {
                                      fontSize: "1.75em",
                                      paddingLeft: "2px",
                                  }
                                : {}
                        }>
                        {data.collaborators}
                    </Text>
                </Flex>
            );
        }
    }

    function thumbnail() {
        const thumbnailSize = isForVr ? "14vw" : "size-2400";
        if (data.thumbnailUrl === "notFound") {
            // thumbnail not found
            return (
                <Flex
                    width={thumbnailSize}
                    height={thumbnailSize}
                    alignItems="center"
                    justifyContent="center">
                    <Image
                        width={isForVr ? "size-700" : "size-800"}
                        height={isForVr ? "size-700" : "size-800"}
                        alt={t("accessibility.asset.thumbnail")}
                        src={noThumbnail}
                    />
                </Flex>
            );
        } else if (data.thumbnailUrl) {
            return (
                <View
                    width={thumbnailSize}
                    height={thumbnailSize}>
                    <Image
                        minWidth={thumbnailSize}
                        minHeight={thumbnailSize}
                        maxWidth={thumbnailSize}
                        maxHeight={thumbnailSize}
                        alt={t("accessibility.asset.thumbnail")}
                        src={data.thumbnailUrl}
                        UNSAFE_style={{
                            borderRadius: "8px",
                        }}
                    />
                </View>
            );
        } else {
            // thumbnail is loading
            return (
                <View
                    width={thumbnailSize}
                    height={thumbnailSize}
                    backgroundColor="gray-200"
                    UNSAFE_style={{
                        borderRadius: "8px",
                    }}>
                    <ProgressCircleView isBlocked={data.isBlocked} />
                </View>
            );
        }
    }

    return (
        <Flex
            direction="column"
            alignContent="start"
            marginBottom="size-200">
            {thumbnail()}
            <View marginTop="size-200">
                <Flex direction="row" justifyContent="space-between">
                    <Text
                        UNSAFE_style={{
                            fontSize: isForVr ? "1.75em" : "",
                        }}>
                        {data.displayName &&
                            getTrimmedString(data.displayName)}
                    </Text>
                    {tabType == TabType.yourReviews && (
                        <AssetActionMenu asset={data} isForVr={isForVr} />
                    )}
                </Flex>
                <Flex direction="row" justifyContent="space-between">
                    <Text
                        UNSAFE_style={{
                            color: "rgb(150,150,150)",
                            fontSize: isForVr ? "1.75em" : "",
                        }}>
                        {data.modifyDate &&
                            getRelativeFormattedTime(
                                data.modifyDate,
                                locale,
                            )}
                    </Text>
                    {collaborators()}
                </Flex>
            </View>
        </Flex>
    );
}
