/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { querySelectorAllDeep } from "query-selector-shadow-dom";
import { useEffect, useState } from "react";

import {
    ChangeInputFieldTextMessage,
    PostMessageSharedApi,
    SharedIncomingEvents,
} from "@src/api/PostMessageSharedApi";

export interface TextInput {
    name: string;
    element: HTMLInputElement;
}

const useVuplex = new URL(location.href).searchParams.get("use-vuplex");

export function useInputPostBus(
    postMessageBusApi: PostMessageSharedApi<any> | undefined,
) {
    const [inputFields, setInputFields] = useState<TextInput[]>([]);

    function refreshInputElements() {
        if (postMessageBusApi) {
            const inputs = querySelectorAllDeep(
                'input[type="text"], input[type="password"], input[type="email"], input[type="search"], input:not([type]), textarea',
            );
            setInputFields((oldFields) => {
                oldFields.forEach(({ element }) => {
                    element.removeEventListener(
                        "focus",
                        postMessageBusApi.onInputFocus,
                    );
                });
                const newFields: TextInput[] = [];
                inputs.forEach((el: unknown) => {
                    const element = el as HTMLInputElement;
                    element.name = element.name || window.crypto.randomUUID();

                    if (useVuplex) {
                        const oldColor = window.getComputedStyle(element).color;
                        console.log("oldColor", oldColor);
                        if (oldColor !== "rgba(0, 0, 0, 0)") {
                            element.style.color = "transparent";
                            element.style.textShadow = `0 0 0 ${oldColor}`;
                        }
                    }

                    element.addEventListener(
                        "focus",
                        postMessageBusApi.onInputFocus,
                    );
                    element.onfocus = postMessageBusApi.onInputFocus;
                    newFields.push({
                        element,
                        name: element.name,
                    });
                });
                return newFields;
            });
        }
    }

    useEffect(() => {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value",
        )?.set;
        const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            "value",
        )?.set;
        function handleMessage(e: any) {
            const data = e.detail as ChangeInputFieldTextMessage["data"];
            const field = inputFields.find(
                (field) => field.name === data.fieldName,
            );
            if (field) {
                const setter = field.element.tagName.toLocaleLowerCase() === "input" ? nativeInputValueSetter : nativeTextAreaValueSetter;
                if (field.element.value !== data.oldText) {
                    console.warn("Old text mismatch", { data });
                }
                if(setter){
                    try {
                        setter.call(field.element, data.newText);
                        const event = new Event("input", { bubbles: true });
                        field.element.dispatchEvent(event);
                    } catch (e) {
                        console.error(e);
                        field.element.value = data.newText;
                    }
                } else {
                    field.element.value = data.newText;
                }
            } else {
                console.error("Failed to find element by name", { data });
            }
        }
        // @ts-ignore
        window.sendMessage = handleMessage;

        if (postMessageBusApi) {
            postMessageBusApi.addEventListener(
                SharedIncomingEvents.changeInput,
                handleMessage,
            );
        }
        return () => {
            postMessageBusApi?.removeEventListener(
                SharedIncomingEvents.changeInput,
                handleMessage,
            );
        };
    }, [inputFields, postMessageBusApi]);

    useEffect(() => {
        refreshInputElements();
    }, [location.pathname, location.search, postMessageBusApi]);

    return { refreshInputElements };
}
