/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PinManager, PinManagerOptions, type AdobeViewer } from "@3di/adobe-3d-viewer";
import { useEffect, useState } from "react";

import { EventType, useAnalytics } from "./useAnalytics";
import headUrl from "../images/pin-default.png";
import groupHoverSheetUrl from "../images/pin-group-sheet-hover.png";
import groupOccludedSheetUrl from "../images/pin-group-sheet-occluded.png";
import groupSheetUrl from "../images/pin-group-sheet.png";
import headHoverUrl from "../images/pin-hover.png";
import headOccludedUrl from "../images/pin-outline.png";
import headEditUrl from "../images/pin-pending.png";
import headSelectedUrl from "../images/pin-selected.png";
import { CommentsApi } from "@src/comments-api/CommentsApi";
import { useHi5UserContext } from "@src/contexts/HI5UserProvider";
import { PinningSession } from "@src/scene/PinningSession";

export function usePinningSession(
    commentsApi: CommentsApi,
    viewerInstance: AdobeViewer | undefined,
    assetId: string,
    guestAccessToken?: string,
) {
    const { accessToken, userId } = useHi5UserContext();
    const { sendLaunchAnalytics } = useAnalytics();
    const [pinningSession, setPinningSession] = useState<PinningSession>();


    useEffect(() => {
        const authToken = accessToken || guestAccessToken;
        if (
            authToken &&
            assetId &&
            viewerInstance
        ) {
            viewerInstance.onAdobeViewerRenderCompleteObservable.addOnce(() => {
                try {
                    const pinOptions: PinManagerOptions = {
                        pinDisplayConfig: {
                            spriteSize: 351,
                            headUrl,
                            headHoverUrl,
                            headSelectedUrl,
                            headEditUrl,
                            headOccludedUrl,
                        },
                        modelBounds: viewerInstance.getRootMesh()?.getHierarchyBoundingVectors(),
                        modelScale: viewerInstance.modelScale,
                        grouping: {
                            enabled: false,
                            groupScreenRatio: 0.2,
                            minGroupPinCount: 3,
                            groupPinHeadUrl: groupSheetUrl,
                            groupPinHeadOccludedUrl: groupOccludedSheetUrl,
                            groupPinHoverUrl: groupHoverSheetUrl,
                        }
                    }
                    const modelBounds =
                    viewerInstance && viewerInstance.sceneManager.scene.getWorldExtends();
                    pinOptions.modelBounds = modelBounds;
                    pinOptions.modelScale = viewerInstance && viewerInstance.modelScale;
                    const pinManager = new PinManager(viewerInstance, pinOptions);
                    viewerInstance.plugins.pins = pinManager;

                    const pinningSession = new PinningSession(pinManager, commentsApi);

                    setPinningSession(pinningSession)

                    sendLaunchAnalytics(
                        EventType.success,
                        window.location.pathname,
                    );
                } catch (e) {
                    sendLaunchAnalytics(
                        EventType.error,
                        window.location.pathname,
                    );
                    console.log("Error creating PinningSession", e);
                }
            });
        }
    }, [
        accessToken,
        guestAccessToken,
        userId,
        assetId,
        viewerInstance,
    ]);

    useEffect(() => {
        return () => {
            if (pinningSession) {
                pinningSession.pinManager.dispose();
                pinningSession.dispose();
            }
        }
    }, [pinningSession])

    return pinningSession;
}
